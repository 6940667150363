/*
@File: Ervy Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
sign
This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Navbar Area Two CSS
** - Saas Home Area CSS
** - Marketing Home Area CSS
** - Startup Home Area CSS
** - SEO Agency Home Area CSS
** - Freelancer Home Area CSS
** - Features Section CSS
** - Services Section CSS
** - Video Section CSS
** - Counter Section CSS
** - Benefit Section CSS
** - Support Section CSS
** - Software Section CSS
** - Pricing Section CSS
** - Client Section CSS
** - Subscribe Section CSS
** - Partner Section CSS
** - Talk Section CSS
** - Top Services Section CSS
** - What We Do Section CSS
** - History Section CSS
** - Performance Section CSS
** - Productive Section CSS
** - Needs Section CSS
** - Project Section CSS
** - Saying Section CSS
** - Faq Section CSS
** - Provide Section CSS
** - Solutions Section CSS
** - Contact Section CSS
** - Footer Section CSS
** - Back To Top Section CSS
** - Others Section CSS
*/


/*================================================
Default CSS
=================================================*/

@import url("https://fonts.googleapis.com/css?family=Cairo&display=swap");
$body-font-family: "Cairo",
sans-serif;
$heading-font-family: "Cairo",
sans-serif;
$main-color: #fd5a0e;
$optional-color: #ffdecf;
$white-color: #ffffff;
$black-color: #343b4e;
$paragraph-color: #737b9a;
$font-size: 15px;
$transition: 0.5s;
body {
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
        family: $body-font-family;
    }
}

img {
    max-width: 100%;
    height: auto;
}

button {
    outline: 0 !important;
}

.d-table {
    width: 100%;
    height: 100%;
    &-cell {
        vertical-align: middle;
    }
}

.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}

.pt-100 {
    padding-top: 100px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-100 {
    padding-bottom: 100px;
}

a {
    text-decoration: none;
    transition: $transition;
    outline: 0 !important;
    color: $black-color;
    &:hover {
        text-decoration: none;
        color: $main-color;
    }
}

p {
    font-size: $font-size;
    line-height: 1.8;
    color: $paragraph-color;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    color: $black-color;
    font-family: $heading-font-family;
}

.default-btn {
    display: inline-block;
    color: $main-color;
    background-color: $white-color;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 400;
    transition: $transition;
    border-radius: 5px;
    border: 1px solid $main-color;
    position: relative;
    i {
        position: relative;
        top: 0px;
        padding-left: 6px;
        font-size: 14px;
    }
    &:hover {
        background-color: $main-color;
        color: $white-color;
    }
}

.section-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
        text-transform: capitalize;
    }
    h2 {
        font-size: 40px;
        margin: 15px auto 10px;
        max-width: 490px;
    }
}

i {
    &::before {
        margin-left: 0;
    }
}


/*=============================
Preloader CSS
===============================*/

.preloader-area {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $main-color;
    z-index: 999999;
    left: 0;
    top: 0;
}

.spinner {
    margin: -85px auto 0 -65px;
    width: 65px;
    height: 65px;
    position: relative;
    text-align: center;
    animation: sk-rotate 2s infinite linear;
    position: absolute;
    left: 50%;
    top: 50%;
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $white-color;
    border-radius: 100%;
    animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}


/*================================================
Navbar Area CSS
=================================================*/

.exto-responsive-nav {
    display: none;
}

.exto-nav {
    background-color: transparent;
    padding: {
        top: 15px;
        bottom: 15px;
        right: 0;
        left: 0;
    }
    .navbar {
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
                right: auto;
            }
            .nav-item {
                position: relative;
                padding: {
                    top: 15px;
                    bottom: 15px;
                    left: 0;
                    right: 0;
                }
                a {
                    font: {
                        size: 16px;
                        weight: 600;
                    }
                    color: $black-color;
                    text-transform: capitalize;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                    margin: {
                        left: 15px;
                        right: 15px;
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        color: $main-color;
                    }
                    i {
                        font-size: 16px;
                        position: relative;
                        top: 2px;
                        margin-left: 1px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover,
                &:focus,
                &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 80px;
                    left: 0;
                    width: 230px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;
                    padding: {
                        top: 20px;
                        left: 25px;
                        right: 25px;
                        bottom: 20px;
                    }
                    li {
                        padding: 0;
                        a {
                            text-transform: capitalize;
                            padding: 8px 0;
                            margin: 0;
                            color: #5b5b98;
                            font: {
                                size: 15px;
                            }
                            &:hover,
                            &:focus,
                            &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            left: -245px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            li {
                                a {
                                    color: #5b5b98;
                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: -245px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
                                    li {
                                        a {
                                            color: #5b5b98;
                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -245px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                                            li {
                                                a {
                                                    color: #5b5b98;
                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: 195px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                                                    li {
                                                        a {
                                                            color: #5b5b98;
                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: 195px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                                            li {
                                                                a {
                                                                    color: #5b5b98;
                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: 195px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                                                    li {
                                                                        a {
                                                                            color: #5b5b98;
                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -15px;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: -15px;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }
        }
        .others-options {
            margin-left: 5px;
            .language-list {
                display: inline-block;
                .nice-select {
                    background-color: transparent;
                    border: none;
                    span {
                        font-size: 16px;
                        color: $paragraph-color;
                        transition: all 0.5s;
                        font-weight: 600;
                        &:hover {
                            color: $main-color;
                        }
                    }
                    &::after {
                        height: 9px;
                        width: 9px;
                        border-bottom: 3px solid $paragraph-color !important;
                        border-right: 3px solid $paragraph-color !important;
                        transition: all 0.5s;
                    }
                    &.open {
                        .list {
                            border-radius: 0;
                            box-shadow: none;
                        }
                    }
                    .option {
                        &.focus {
                            color: $white-color !important;
                            background-color: $main-color !important;
                        }
                        &:hover {
                            color: $white-color;
                            background-color: $main-color !important;
                        }
                    }
                    &:hover {
                        &::after {
                            border-bottom: 3px solid $main-color !important;
                            border-right: 3px solid $main-color !important;
                        }
                        span {
                            color: $main-color;
                        }
                    }
                }
            }
            .saas-nav-btn {
                background-color: $main-color;
                color: $white-color;
                padding: 10px 20px;
                border-radius: 4px;
                margin-left: 10px;
                transition: all 0.5s;
                font-size: 16px;
                font-weight: 600;
                i {
                    font-size: 14px;
                }
                &:hover {
                    background-color: $black-color;
                    color: $white-color !important;
                }
            }
            &.saas-option {
                margin: 0;
                .saas-nav-btn {
                    background-color: transparent;
                    border: 1px solid $white-color;
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border: 1px solid $main-color;
                    }
                }
            }
            &.startup-option {
                .language-list {
                    .nice-select {
                        span {
                            color: #5b5b98;
                            transition: all 0.5s;
                        }
                        &::after {
                            border-bottom: 3px solid #5b5b98 !important;
                            border-right: 3px solid #5b5b98 !important;
                            transition: all 0.5s;
                        }
                        .option {
                            &.focus {
                                color: $white-color !important;
                                background-color: $main-color !important;
                            }
                            &:hover {
                                color: $white-color;
                                background-color: $main-color !important;
                            }
                        }
                        &:hover {
                            &::after {
                                border-bottom: 3px solid $main-color !important;
                                border-right: 3px solid $main-color !important;
                            }
                            span {
                                color: $main-color;
                            }
                        }
                    }
                }
                .sidebar-menu {
                    cursor: pointer;
                    background-color: #002c8c;
                    padding: 5px;
                    display: inline-block;
                    position: relative;
                    top: 7px;
                    margin-left: 10px;
                    span {
                        border-bottom: 1px solid $white-color;
                        display: block;
                        padding-bottom: 5px;
                        width: 20px;
                        &:first-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.navbar-area {
    background-color: transparent;
    transition: $transition;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        transition: $transition;
    }
}

.search-overlay {
    display: none;
    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: $white-color;
        z-index: 2;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 25px;
        .search-form {
            position: relative;
            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    left: 10px;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;
                &:hover,
                &:focus {
                    color: $main-color;
                }
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .navbar-area {
        padding: {
            top: 20px;
            bottom: 20px;
        }
        &.is-sticky {
            border-bottom: none;
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            padding: {
                top: 15px;
                bottom: 15px;
            }
        }
    }
    .exto-responsive-nav {
        display: block;
        .exto-responsive-menu {
            position: relative;
            &.mean-container {
                .mean-nav {
                    ul {
                        font-size: 14px;
                        li {
                            a {
                                font-size: 15px;
                                &.active {
                                    color: $main-color;
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    height: 336px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
                }
                .others-options {
                    // display: none;
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: $black-color;
                    span {
                        background: $black-color;
                    }
                }
            }
        }
        .logo {
            position: relative;
            width: 50%;
            z-index: 999;
        }
    }
    .exto-nav {
        display: none;
    }
}


/*================================================
Navbar Area Two CSS
=================================================*/

.navbar-area-two {
    background-color: #013a77;
    transition: $transition;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: -8px 20px 28px 0 rgba(255, 255, 255, 0.06);
        background: #150346 !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        transition: $transition;
        .exto-nav {
            .navbar {
                .navbar-nav {
                    .nav-item {
                        a {
                            color: $white-color;
                        }
                    }
                }
                .others-options {
                    margin-left: 30px;
                    .option-item {
                        color: $white-color;
                        .search-btn {
                            color: $white-color;
                        }
                        .close-btn {
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .exto-responsive-nav {
        display: none;
    }
    .exto-nav {
        background-color: transparent;
        padding: {
            top: 15px;
            bottom: 15px;
            right: 0;
            left: 0;
        }
        .navbar {
            padding: {
                right: 0;
                top: 0;
                left: 0;
                bottom: 0;
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;
            }
            .navbar-nav {
                margin: {
                    left: auto;
                    right: auto;
                }
                .nav-item {
                    position: relative;
                    padding: {
                        top: 15px;
                        bottom: 15px;
                        left: 0;
                        right: 0;
                    }
                    a {
                        font: {
                            size: 16px;
                            weight: 600;
                        }
                        color: $white-color;
                        text-transform: capitalize;
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        margin: {
                            left: 15px;
                            right: 15px;
                        }
                        &:hover,
                        &:focus,
                        &.active {
                            color: $main-color;
                        }
                        i {
                            font-size: 16px;
                            position: relative;
                            top: 2px;
                            margin-left: 1px;
                        }
                    }
                    &:last-child {
                        a {
                            margin-right: 0;
                        }
                    }
                    &:first-child {
                        a {
                            margin-left: 0;
                        }
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        a {
                            color: $main-color;
                        }
                    }
                    .dropdown-menu {
                        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        background: $white-color;
                        position: absolute;
                        border: none;
                        top: 80px;
                        left: 0;
                        width: 230px;
                        z-index: 99;
                        display: block;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 0;
                        transition: all 0.3s ease-in-out;
                        padding: {
                            top: 20px;
                            left: 25px;
                            right: 25px;
                            bottom: 20px;
                        }
                        li {
                            padding: 0;
                            a {
                                text-transform: capitalize;
                                padding: 8px 0;
                                margin: 0;
                                color: #000000;
                                font: {
                                    size: 15px;
                                }
                                &:hover,
                                &:focus,
                                &.active {
                                    color: $main-color;
                                }
                            }
                            .dropdown-menu {
                                left: -245px;
                                top: 0;
                                opacity: 0;
                                visibility: hidden;
                                li {
                                    a {
                                        color: #696997;
                                        &:hover,
                                        &:focus,
                                        &.active {
                                            color: #000000;
                                        }
                                    }
                                    .dropdown-menu {
                                        left: -245px;
                                        top: 0;
                                        opacity: 0;
                                        visibility: hidden;
                                        li {
                                            a {
                                                color: #696997;
                                                text-transform: capitalize;
                                                &:hover,
                                                &:focus,
                                                &.active {
                                                    color: $main-color;
                                                }
                                            }
                                            .dropdown-menu {
                                                left: -245px;
                                                top: 0;
                                                opacity: 0;
                                                visibility: hidden;
                                                li {
                                                    a {
                                                        color: #696997;
                                                        text-transform: capitalize;
                                                        &:hover,
                                                        &:focus,
                                                        &.active {
                                                            color: $main-color;
                                                        }
                                                    }
                                                    .dropdown-menu {
                                                        left: 195px;
                                                        top: 0;
                                                        opacity: 0;
                                                        visibility: hidden;
                                                        li {
                                                            a {
                                                                color: #696997;
                                                                text-transform: capitalize;
                                                                &:hover,
                                                                &:focus,
                                                                &.active {
                                                                    color: $main-color;
                                                                }
                                                            }
                                                            .dropdown-menu {
                                                                left: 195px;
                                                                top: 0;
                                                                opacity: 0;
                                                                visibility: hidden;
                                                                li {
                                                                    a {
                                                                        color: #696997;
                                                                        text-transform: capitalize;
                                                                        &:hover,
                                                                        &:focus,
                                                                        &.active {
                                                                            color: $main-color;
                                                                        }
                                                                    }
                                                                    .dropdown-menu {
                                                                        left: 195px;
                                                                        top: 0;
                                                                        opacity: 0;
                                                                        visibility: hidden;
                                                                        li {
                                                                            a {
                                                                                color: #696997;
                                                                                text-transform: capitalize;
                                                                                &:hover,
                                                                                &:focus,
                                                                                &.active {
                                                                                    color: $main-color;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    &.active {
                                                                        a {
                                                                            color: $main-color;
                                                                        }
                                                                    }
                                                                    &:hover {
                                                                        .dropdown-menu {
                                                                            opacity: 1;
                                                                            visibility: visible;
                                                                            top: -15px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            &.active {
                                                                a {
                                                                    color: $main-color;
                                                                }
                                                            }
                                                            &:hover {
                                                                .dropdown-menu {
                                                                    opacity: 1;
                                                                    visibility: visible;
                                                                    top: -15px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &.active {
                                                        a {
                                                            color: $main-color;
                                                        }
                                                    }
                                                    &:hover {
                                                        .dropdown-menu {
                                                            opacity: 1;
                                                            visibility: visible;
                                                            top: -15px;
                                                        }
                                                    }
                                                }
                                            }
                                            &.active {
                                                a {
                                                    color: $main-color;
                                                }
                                            }
                                            &:hover {
                                                .dropdown-menu {
                                                    opacity: 1;
                                                    visibility: visible;
                                                    top: -15px;
                                                }
                                            }
                                        }
                                    }
                                    &.active {
                                        a {
                                            color: $main-color;
                                        }
                                    }
                                    &:hover {
                                        .dropdown-menu {
                                            opacity: 1;
                                            visibility: visible;
                                            top: -15px;
                                        }
                                    }
                                }
                            }
                            &.active {
                                a {
                                    color: $main-color;
                                }
                            }
                            &:hover {
                                .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: -15px;
                                }
                            }
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: 100%;
                        }
                    }
                }
            }
            .others-options {
                margin-right: 20px;
                .option-item {
                    color: $white-color;
                    display: inline-block;
                    position: relative;
                    line-height: 1;
                    margin-left: 10px;
                    width: 15px;
                    .search-btn {
                        cursor: pointer;
                        transition: $transition;
                        color: $white-color;
                        font: {
                            size: 15px;
                            weight: 600;
                        }
                        &:hover {
                            color: $main-color;
                        }
                    }
                    .close-btn {
                        cursor: pointer;
                        display: none;
                        transition: $transition;
                        width: 20px;
                        color: $white-color;
                        font-size: 15px;
                        &.active {
                            display: block;
                        }
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                &.seo-option {
                    margin-left: 0 !important;
                    .sidebar-menu-icon {
                        margin-left: 10px;
                        cursor: pointer;
                        display: inline-block;
                    }
                    .language-list {
                        display: inline-block;
                        .nice-select {
                            background-color: transparent;
                            border: none;
                            span {
                                color: $white-color;
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            &::after {
                                border-bottom: 3px solid $white-color !important;
                                border-right: 3px solid $white-color !important;
                                transition: all 0.5s;
                            }
                            .option {
                                &.focus {
                                    color: $white-color !important;
                                    background-color: $main-color !important;
                                }
                                &:hover {
                                    color: $white-color;
                                    background-color: $main-color !important;
                                }
                            }
                            &:hover {
                                &::after {
                                    border-bottom: 3px solid $main-color !important;
                                    border-right: 3px solid $main-color !important;
                                }
                                span {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .seo-cart-icon {
                        display: inline-block;
                        a {
                            color: $white-color;
                            position: relative;
                            &:hover {
                                color: $main-color;
                            }
                            .point {
                                position: absolute;
                                top: -12px;
                                left: -6px;
                                background-color: red;
                                width: 15px;
                                height: 15px;
                                font-size: 10px;
                                line-height: 15px;
                                border-radius: 50%;
                                text-align: center;
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &.freelancer-nav {
        .exto-nav .navbar .navbar-nav {
            margin-right: 0;
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .navbar-area-two .exto-nav .navbar .others-options.seo-option .seo-cart-icon a {
        color: #eb1b1b;
        position: relative;
    }
    .navbar-area-two {
        padding: {
            top: 20px;
            bottom: 20px;
        }
        &.is-sticky {
            border-bottom: none;
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            padding: {
                top: 15px;
                bottom: 15px;
            }
        }
        .exto-responsive-nav {
            display: block;
            .exto-responsive-menu {
                position: relative;
                &.mean-container {
                    .mean-nav {
                        ul {
                            font-size: 14px;
                            li {
                                a {
                                    font-size: 15px;
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                                li {
                                    a {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                    .navbar-nav {
                        overflow-y: scroll;
                        height: 336px;
                        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
                    }
                    .others-options {
                        //  display: none;
                    }
                }
            }
            .mean-container {
                a {
                    &.meanmenu-reveal {
                        color: $white-color;
                        span {
                            background: $white-color;
                        }
                    }
                }
            }
            .logo {
                position: relative;
                width: 50%;
                z-index: 999;
            }
        }
        .exto-nav {
            display: none;
        }
    }
}


/*================================================
Saas Home Area CSS
=================================================*/

.saas-home-area {
    position: relative;
    height: 100vh;
    z-index: 1;
    overflow: hidden;
    background-image: url(../img/saas-banner/saas-banner.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    .hero-content {
        margin-top: 70px;
        h1 {
            font-size: 60px;
            color: $black-color;
            font-weight: bold;
            margin: 20px 0 30px 0;
        }
        p {
            color: #7d8087;
            font-weight: 500;
            margin: 0 0 0 0;
            font-size: 16px;
        }
        span {
            display: inline-block;
            font-size: 15px;
            color: $main-color;
            background-color: #eedbb8;
            padding: 8px 16px;
            font-weight: 400;
            border-radius: 30px;
        }
        .saas-btn {
            margin-top: 35px;
            .saas-btn-one {
                display: inline-block;
                color: $white-color;
                background-color: $main-color;
                padding: 14px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 5px;
                i {
                    position: relative;
                    top: 0px;
                    padding-left: 6px;
                    font-size: 14px;
                }
                &:hover {
                    background-color: $black-color;
                    color: $white-color !important;
                }
            }
            .saas-btn-two {
                display: inline-block;
                color: $white-color;
                background-color: $main-color;
                padding: 14px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 5px;
                margin-left: 15px;
                i {
                    position: relative;
                    top: 0px;
                    padding-left: 6px;
                    font-size: 14px;
                }
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}

.saas-image {
    position: relative;
    img {
        position: absolute;
        left: 0;
        top: 40px;
        animation: movebounce 5s linear infinite;
        width: 60%;
        &:last-child {
            animation: movebounce 6s linear infinite;
            left: 250px;
            top: 50px;
        }
    }
}

.circle-img {
    position: absolute;
    left: 55%;
    top: 60%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
    right: 0;
    margin: 0 auto;
    width: 35%;
    img {
        animation-name: rotateMe;
        animation-duration: 35s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.shape-img1 {
    position: absolute;
    left: 10%;
    bottom: 25%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

.shape-img2 {
    position: absolute;
    left: 5%;
    top: 10%;
    z-index: -1;
    animation-name: rotateMe;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape-img3 {
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: -1;
    -webkit-animation: animationFramesOne 20s infinite linear;
    animation: animationFramesOne 20s infinite linear;
}

.shape-img4 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    z-index: -1;
    top: 20%;
    -webkit-animation: rotate3d 4s linear infinite;
    animation: rotate3d 4s linear infinite;
}

.shape-img5 {
    position: absolute;
    left: 20%;
    top: 20%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

@keyframes MOVE-BG {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(88px);
    }
}

@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}


/*================================================
Features Section CSS
=================================================*/

.features-section {
    position: relative;
}

.features-area-content {
    h2 {
        font-size: 40px;
        margin: 20px 0 16px 0;
    }
    p {
        font-weight: 400;
        color: #a0a0a0;
        margin: 0 0 0 0;
    }
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
    }
    .features-text {
        margin-top: 30px;
        h3 {
            font-size: 18px;
            margin: 18px 0 8px 0;
        }
        p {
            color: #a0a0a0;
            margin: 0 0 0 0;
        }
        &.bg-f4ffb3 {
            .icon {
                i {
                    display: inline-block;
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    border-radius: 50px;
                    background-color: #f4ffb3;
                    text-align: center;
                    &::before {
                        font-size: 35px;
                    }
                }
            }
        }
        &.bg-ffe7dc {
            .icon {
                i {
                    display: inline-block;
                    height: 60px;
                    width: 60px;
                    line-height: 60px;
                    border-radius: 50px;
                    background-color: #ffe7dc;
                    text-align: center;
                    &::before {
                        font-size: 35px;
                    }
                }
            }
        }
    }
    .features-btn {
        margin-top: 30px;
    }
}

.features-image {
    position: relative;
    .features-shape {
        position: absolute;
        bottom: -40px;
        z-index: -1;
        width: 638px;
        -webkit-animation: movebounce 5s linear infinite;
        animation: movebounce 5s linear infinite;
    }
    .features-arrow {
        position: absolute;
        top: -18px;
        z-index: -1;
        right: 50px;
        width: 160px;
    }
}


/*================================================
Services Section CSS
=================================================*/

.services-section {
    position: relative;
}

.services-area-content {
    h3 {
        font-size: 36px;
        margin: 21px 0 20px 0;
    }
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
    }
    p {
        color: #a0a0a0;
        font-weight: 400;
    }
    .services-btn {
        margin-top: 30px;
    }
}

.col-lg-6 .row .col-lg-6:nth-child(1) .services-image {
    border-right: 1px solid #f9f5f5;
    border-bottom: 1px solid #f9f5f5;
}

.col-lg-6 .row .col-lg-6:nth-child(3) .services-image {
    border-right: 1px solid #f9f5f5;
}

.col-lg-6 .row .col-lg-6:nth-child(4) .services-image {
    border-top: 1px solid #f9f5f5;
}

.single-services {
    position: relative;
    .services-image {
        text-align: center;
        transition: $transition;
        padding: 30px 20px;
        position: relative;
        h3 {
            font-size: 16px;
            color: #3e435e;
            margin: 16px 0 0 0;
        }
        &:hover {
            background-color: $white-color;
            -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        }
    }
}


/*================================================
Video Section CSS
=================================================*/

.video-content {
    text-align: center;
    background-image: url(../../assets/img/preview.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px;
    padding-bottom: 150px;
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $main-color;
        left: 0;
        top: 0;
        opacity: 0.4;
        z-index: -1;
    }
    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 100px;
        background-color: $white-color;
        border-radius: 50%;
        color: $main-color;
        position: relative;
        z-index: 1;
        i {
            font-size: 30px;
            position: relative;
            top: 2px;
            left: 4px;
        }
        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover,
        &:focus {
            background-color: $main-color;
            color: $white-color;
        }
    }
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}


/*================================================
Counter Section CSS
=================================================*/

.fun-facts-area {
    background-image: linear-gradient(to top, #ffffff, #e5f5df);
}

.single-fun-fact {
    overflow: hidden;
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    transition: $transition;
    margin-bottom: 30px;
    h3 {
        position: relative;
        color: $black-color;
        margin-bottom: 8px;
        font: {
            size: 35px;
            weight: 700;
        }
        .sign-icon {
            display: inline-block;
            font-size: 30px;
            margin-left: 2px;
        }
    }
    p {
        line-height: initial;
        color: $black-color;
        margin: 0 0 0 0;
        font: {
            weight: 600;
        }
        padding-bottom: 23px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            height: 3px;
            width: 50px;
            background-color: #343c4e;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    &:hover {
        transform: translateY(-10px);
    }
}


/*================================================
Benefit Section CSS
=================================================*/

.benefit-section {
    position: relative;
}

.benefit-area-content {
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    padding: 40px 30px;
    position: relative;
    padding-left: 100px;
    margin-bottom: 30px;
    transition: $transition;
    i {
        position: absolute;
        left: 30px;
        color: $main-color;
        top: 32px;
        transition: $transition;
        &::before {
            font-size: 50px;
        }
    }
    h3 {
        font-size: 20px;
        margin: 0 0 14px 0;
        transition: $transition;
    }
    p {
        color: #a0a0a0;
        font-weight: 500;
        transition: $transition;
    }
    &:hover {
        background-color: $main-color;
        transform: translateY(-10px);
        i {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
}


/*================================================
Support Section CSS
=================================================*/

.support-section {
    position: relative;
}

.support-image {
    position: relative;
    text-align: center;
    .support-shape {
        position: absolute;
        top: 40px;
        z-index: -1;
        width: 638px;
        -webkit-animation: movebounce 5s linear infinite;
        animation: movebounce 5s linear infinite;
        text-align: center;
        right: -33px;
    }
    .support-arrow {
        position: absolute;
        top: -4px;
        z-index: -1;
        right: 90px;
        width: 170px;
    }
}

.support-area-content {
    h3 {
        font-size: 36px;
        margin: 21px 0 20px 0;
    }
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
        text-transform: capitalize;
    }
    p {
        color: #a0a0a0;
        font-weight: 400;
    }
    .support-btn {
        margin-top: 25px;
    }
}


/*================================================
Software Section CSS
=================================================*/

.software-section {
    position: relative;
}

.software-title {
    text-align: center;
    max-width: 620px;
    margin: 0 auto 50px;
    h2 {
        font-size: 40px;
        margin: -9px 0 0 0;
    }
}

.tab {
    .tabs_item {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.software-list-tab {
    .tabs {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            left: -5px;
            right: -5px;
            bottom: 50px;
        }
        li {
            -ms-flex: 0 0 16.6666666667%;
            -webkit-box-flex: 0;
            flex: 0 0 20.6666666667%;
            max-width: 20.6666666667%;
            text-align: center;
            margin-right: 5px;
            margin-left: 5px;
            a {
                display: block;
                border-radius: 2px;
                color: #292a2c;
                box-shadow: 0px 10px 40px 0px rgba(107, 125, 179, 0.11);
                padding: {
                    right: 18px;
                    top: 15px;
                    bottom: 18px;
                    left: 18px;
                }
                font: {
                    size: 16px;
                    weight: 700;
                }
                i {
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: normal;
                    display: inline-block;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    background: $optional-color;
                    border-radius: 50px;
                    color: #424657;
                    margin-right: 8px;
                }
            }
            &.current,
            &:hover {
                a {
                    background-color: $white-color;
                    color: $main-color;
                    box-shadow: 0px 10px 40px 0px rgba(222, 222, 222, 0.52);
                    i {
                        color: $white-color;
                        background-color: $main-color;
                    }
                }
            }
        }
    }
    .tab_content {
        .tabs_item {
            .tab-image {
                position: relative;
                .tab-shape {
                    position: absolute;
                    bottom: 20px;
                    z-index: -1;
                    -webkit-animation: movebounce 5s linear infinite;
                    animation: movebounce 5s linear infinite;
                    text-align: center;
                    left: -80px;
                    width: 1230px;
                }
                .tab-arrow {
                    position: absolute;
                    top: -25px;
                    z-index: -1;
                    left: -33px;
                    width: 70px;
                }
                .tab-arrow-two {
                    position: absolute;
                    bottom: -25px;
                    z-index: -1;
                    right: 50px;
                    width: 70px;
                }
            }
        }
    }
}


/*================================================
Pricing Section CSS
=================================================*/

.pricing-section {
    position: relative;
    padding-bottom: 130px;
    .pricing-btn-wrap {
        .price-btn {
            margin-top: 25px;
            display: inline-block;
            .price-btn-one {
                display: inline-block;
                color: $white-color;
                background-color: $main-color;
                padding: 12px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 5px;
                border: 1px solid $main-color;
                position: relative;
                i {
                    font-size: 14px;
                }
                &.price-two {
                    margin-left: 15px;
                    background-color: transparent;
                    color: $main-color;
                    border: 1px solid $main-color;
                    &:hover {
                        border: 1px solid $main-color;
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:hover {
                    background-color: transparent;
                    color: $main-color;
                    border: 1px solid $main-color;
                }
            }
        }
    }
}

.pricing-area-content {
    h3 {
        font-size: 36px;
        margin: 21px 0 20px 0;
    }
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
        text-transform: capitalize;
    }
    p {
        color: #a0a0a0;
        font-weight: 400;
    }
    &.seo-price-title {
        span {
            background-color: transparent;
            display: block;
            margin-top: -5px;
        }
    }
}

.single-pricing-box {
    background-color: $white-color;
    transition: 0.5s;
    border-radius: 5px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0px 10px 40px 13px rgba(222, 222, 222, 0.52);
    position: relative;
    &.seo-price {
        &.price-z {
            z-index: 111;
        }
        .pricing-header {
            background-color: #150346;
            position: relative;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                top: -18px;
                left: 0;
                width: 100%;
                height: 35px;
                background-color: $white-color;
                z-index: -1;
                border-radius: 50%;
            }
            &::after {
                content: "";
                position: absolute;
                bottom: -18px;
                left: 0;
                width: 100%;
                height: 35px;
                background-color: $white-color;
                z-index: -1;
                border-radius: 50%;
            }
            h3 {
                color: #bbb1de;
            }
        }
        .price {
            color: $main-color;
            span {
                color: #3e435e;
            }
        }
    }
    .pricing-header {
        background-color: #eefce4;
        padding: 30px;
        h3 {
            font-size: 20px;
            color: #5b616f;
            margin: 0 0 0 0;
        }
    }
    .price {
        font-size: 30px;
        color: #5b616f;
        font-weight: bold;
        padding: 20px;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            height: 1px;
            width: 180px;
            background-color: #e5e3eb;
            bottom: 12px;
            left: 0;
            right: 0;
            margin: auto;
        }
        span {
            font-size: 14px;
            font-weight: 400;
        }
    }
    .pricing-features {
        padding-left: 30px;
        margin-bottom: 0;
        text-align: left;
        li {
            list-style-type: none;
            font-size: 16px;
            color: #827f8d;
            font-weight: 400;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        i {
            color: #ffd0ba;
            margin-right: 5px;
            position: relative;
            top: 1px;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .buy-btn {
        margin-top: 20px;
        .buy-btn-one {
            display: inline-block;
            padding: 14px 20px;
            background-color: $white-color;
            color: $main-color;
            border: 2px solid $optional-color;
            border-radius: 2px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            &:hover {
                background-color: $optional-color;
                color: $main-color;
            }
        }
    }
    &.table-price {
        margin-left: 0;
        position: relative;
        left: -40px;
        top: 30px;
    }
}


/*================================================
Client Section CSS
=================================================*/

.client-section {
    position: relative;
    z-index: 1;
    background: radial-gradient( circle, #eef7e8, #f0f9e9, #f3fbea, #f5fceb, #f8feec);
    .client-logo {
        img {
            width: unset;
        }
    }
    .owl-prev {
        position: absolute;
        bottom: 40px;
        right: 20px;
        transition: all 0.5s;
        i {
            width: 50px;
            height: 30px;
            line-height: 30px;
            border: 1px solid $main-color;
            color: $main-color;
            transition: all 0.5s;
            &:hover {
                color: $white-color;
                background-color: $main-color;
            }
        }
    }
    .owl-next {
        position: absolute;
        top: 0;
    }
}

.client-image {
    position: relative;
    img {
        border-radius: 0 0 50% 50%;
    }
    .client-shape {
        position: absolute;
        bottom: 14px;
        width: 410px;
        -webkit-animation: movebounce 5s linear infinite;
        animation: movebounce 5s linear infinite;
        text-align: center;
        right: 20px;
        z-index: -1;
    }
}

.client-content-area {
    background-color: $white-color;
    padding: 35px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    h3 {
        font-size: 20px;
        margin: 0 0 0 0;
    }
    span {
        font-size: 13px;
        color: #909090;
        font-weight: 400;
    }
    p {
        font-weight: 400;
        color: #909090;
        margin: 16px 0 0 0;
    }
    .client-logo {
        margin-top: 20px;
    }
    .icon {
        position: absolute;
        bottom: 36px;
        right: 50px;
        i {
            color: $main-color;
            background-color: transparent;
            border: 2px solid $main-color;
            height: 30px;
            width: 50px;
            display: inline-block;
            text-align: center;
            line-height: 26px;
            border-radius: 2px;
            font-size: 16px;
        }
    }
}


/*================================================
Subscribe Section CSS
=================================================*/

.subscribe-section {
    position: relative;
    overflow: hidden;
}

.subscribe-area-content {
    margin-left: auto;
    max-width: 542px;
    h3 {
        font-size: 36px;
        margin: 21px 0 20px 0;
    }
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
        text-transform: capitalize;
    }
    p {
        color: #a0a0a0;
        font-weight: 400;
        margin: 0 0 0 0;
    }
    .newsletter-form {
        max-width: 550px;
        position: relative;
        margin: {
            left: auto;
            right: auto;
            top: 35px;
        }
        .input-newsletter {
            display: block;
            width: 68%;
            background-color: #f1f1f1;
            border: none;
            height: 50px;
            padding-left: 25px;
            border-radius: 4px;
            padding-top: 0;
            outline: 0;
            color: $black-color;
            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: -12px;
            top: 0;
            background-color: $main-color;
            color: $white-color;
            border: none;
            padding: 14px 30px;
            border-radius: 5px;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            text-transform: capitalize;
            font-size: 15px;
            font-weight: 400;
            font: {
                size: 15px;
                weight: 400;
            }
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
            i {
                margin-left: 5px;
            }
        }
    }
    .social-list {
        position: relative;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 35px;
        li {
            display: inline-block;
            &.list-heading {
                color: #a0a0a0;
                margin-bottom: 0;
                padding-right: 10px;
                font: {
                    size: 18px;
                    weight: 400;
                }
            }
            a {
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #f45a43;
                border: 1px solid #f45a43;
                color: $white-color;
                border-radius: 50%;
                margin: 0 2px;
                display: inline-block;
                text-align: center;
                &:hover {
                    background-color: transparent;
                    color: #f45a43;
                    transition: 0.6s;
                    -webkit-transition: 0.5s;
                }
            }
        }
    }
}

.subscribe-image {
    text-align: right;
    position: relative;
    z-index: 1;
    .main-image {
        img {
            &:nth-child(2) {
                position: absolute;
                left: 40px;
                top: 15px;
            }
            &:nth-child(3) {
                position: absolute;
                left: 40px;
                top: 170px;
            }
            &:nth-child(4) {
                position: absolute;
                left: 40px;
                top: 343px;
                display: none;
            }
        }
    }
    .subscribe-mobile-image {
        display: none;
    }
}

@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}


/*================================================
Partner Section CSS
=================================================*/

.partner-section {
    line-height: 1;
    position: relative;
}

.partner-slider {
    .partner-item {
        text-align: center;
        img {
            display: inline-block;
            margin: auto;
            width: 160px;
        }
    }
}


/*================================================
Talk Section CSS
=================================================*/

.talk-section {
    position: relative;
}

.talk-form {
    background: $white-color;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 30px;
    border-radius: 5px;
    form {
        .form-control {
            height: 55px;
            padding: 10px;
            font-size: 15px;
            color: #0e314c;
            border: 1px solid #f8f8f8;
            border-radius: 0;
            background: #f8f8f8;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
        textarea {
            height: 150px !important;
        }
        .btn {
            font-weight: 400;
            font-size: 16px;
            border: none;
            padding: 15px 40px;
            position: relative;
            border-radius: 4px;
            z-index: 1;
            text-transform: capitalize;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            width: 100%;
            margin-top: 20px;
        }
        .btn-primary {
            color: $white-color;
            background-color: $main-color;
            -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
            box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
            transition: $transition;
            border: 1px solid $main-color;
            &:hover {
                background-color: $white-color;
                color: $main-color;
            }
        }
    }
}

.talk-content-area {
    margin-left: 60px;
    h3 {
        font-size: 40px;
        margin: 20px 0 30px 0;
    }
    span {
        display: inline-block;
        font-size: 15px;
        color: $main-color;
        background-color: #eedbb8;
        padding: 8px 16px;
        font-weight: 400;
        border-radius: 30px;
        text-transform: capitalize;
    }
    p {
        color: #a0a0a0;
        font-weight: 400;
        margin: 0 0 0 0;
    }
    .talk-info {
        margin-top: 25px;
        p {
            color: #a0a0a0;
            font-weight: 400;
            margin: 0 0 0 0;
        }
        h3 {
            font-size: 25px;
            margin: 10px 0 0 0;
        }
    }
}

.talk-image {
    .talk-shape {
        position: absolute;
        bottom: 30px;
        z-index: -1;
        width: 638px;
        animation: movebounce 5s linear infinite;
        text-align: center;
        left: 40px;
    }
    .talk-arrow {
        position: absolute;
        bottom: -16px;
        z-index: -1;
        left: -10px;
        width: 120px;
    }
}


/*================================================
Marketing Home Area CSS
=================================================*/

.marketing-home-area {
    position: relative;
    // background-image: url(../../assets/img/marketing-agency-bg.jpg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 800px;
    z-index: 1;
    overflow: hidden;
    .hero-content {
        max-width: 665px;
        margin-top: -70px;
        h1 {
            font-size: 60px;
            color: $white-color;
            font-weight: bold;
            margin: 20px 0 20px 0;
        }
        p {
            color: $white-color;
            font-weight: 500;
            margin: 0 0 0 0;
            font-size: 16px;
        }
        span {
            display: inline-block;
            font-size: 15px;
            color: $white-color;
            background-color: #4b2a56;
            padding: 8px 16px;
            font-weight: 400;
            border-radius: 30px;
            text-transform: capitalize;
        }
        .marketing-btn {
            margin-top: 35px;
            .marketing-btn-one {
                display: inline-block;
                color: $white-color;
                background-color: #ff8b00;
                padding: 14px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 3px;
                &:hover {
                    background-color: $white-color;
                    color: #ff8b00;
                }
            }
            .video-btn {
                -webkit-animation-delay: 2.6s !important;
                animation-delay: 2.6s !important;
                display: inline-block;
                color: $white-color;
                position: absolute;
                text-transform: capitalize;
                bottom: 2px;
                font-weight: 400;
                font-size: 16px;
                i {
                    position: relative;
                    left: 2px;
                }
                span {
                    display: inline-block;
                    margin-left: 40px;
                    width: 50px;
                    height: 50px;
                    line-height: 36px;
                    position: relative;
                    z-index: 1;
                    text-align: center;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.2);
                    color: $white-color;
                    font-size: 20px;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    margin-right: 30px;
                }
            }
            .video-btn span::after,
            .video-btn span::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, 0.2);
            }
            .video-btn span::before {
                -webkit-animation: ripple 2s linear infinite;
                animation: ripple 2s linear infinite;
            }
            .video-btn span::after {
                -webkit-animation: ripple 2s linear 1s infinite;
                animation: ripple 2s linear 1s infinite;
            }
            &:hover {
                span {
                    background-color: $white-color;
                    color: #130947;
                }
            }
        }
    }
    @-webkit-keyframes ripple {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        75% {
            -webkit-transform: scale(1.75);
            transform: scale(1.75);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(2);
            transform: scale(2);
            opacity: 0;
        }
    }
    @keyframes ripple {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        75% {
            -webkit-transform: scale(1.75);
            transform: scale(1.75);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(2);
            transform: scale(2);
            opacity: 0;
        }
    }
}


/*================================================
Map Section CSS
=================================================*/

.map-area {
    position: relative;
    margin-bottom: -6px;
    iframe {
        width: 100%;
        height: 480px;
        border: none;
    }
}


/*================================================
Freelancer Info Section CSS
=================================================*/

.freelancer-info-section {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #ff6203;
}

.freelancer-info-content {
    h3 {
        font-size: 30px;
        color: $white-color;
        margin: 0 0 14px 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 3px;
            width: 45%;
            background-color: $white-color;
            bottom: 0;
            right: 48px;
            border-radius: 5px;
        }
    }
    .info-social {
        position: relative;
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            &.list-heading {
                color: #a0a0a0;
                margin-bottom: 0;
                padding-right: 10px;
                font: {
                    size: 18px;
                    weight: 400;
                }
            }
            a {
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: transparent;
                border: 1px solid $white-color;
                color: $white-color;
                border-radius: 50%;
                margin: 0 2px;
                display: inline-block;
                text-align: center;
                transition: 0.6s;
                -webkit-transition: 0.5s;
                &:hover {
                    background-color: $white-color;
                    border: 1px solid $white-color;
                    color: $main-color;
                }
            }
        }
    }
}

.info-image {
    text-align: center;
}

.freelancer-info-text {
    h3 {
        font-size: 30px;
        color: $white-color;
        margin: 0 0 10px 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 3px;
            width: 30%;
            background-color: $white-color;
            bottom: 0;
            right: 130px;
            border-radius: 5px;
        }
    }
    a {
        font-size: 20px;
        color: $white-color;
    }
}


/*================================================
Top Services Section CSS
=================================================*/

.top-services-section {
    position: relative;
    z-index: 1;
    .col-lg-3 {
        &:last-child {
            .top-services-content {
                border-right: none;
            }
        }
    }
}

.top-services-area {
    position: relative;
    margin-top: -100px;
    background-color: $white-color;
    padding: 30px;
    box-shadow: 0 13px 27px 0 rgba(197, 197, 197, 0.44);
    .top-services-content {
        border-right: 1px solid #e6e2e8;
        h3 {
            font-size: 20px;
            color: #3e435e;
            margin: 20px 0 12px 0;
        }
        p {
            color: #827f8d;
            font-weight: 400;
            margin: 0 0 0 0;
        }
    }
}


/*================================================
What We Do Section CSS
=================================================*/

.what-we-do-section {
    position: relative;
}

.we-do-content-area {
    span {
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        text-transform: capitalize;
    }
    h3 {
        font-size: 38px;
        color: #624272;
        margin: 10px 0 18px 0;
    }
    p {
        font-weight: 400;
    }
    .we-btn {
        margin-top: 25px;
        .we-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #ff8b00;
            padding: 14px 25px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            border-radius: 3px;
            border: 1px solid #ff8b00;
            &:hover {
                background-color: $white-color;
                color: #ff8b00;
            }
        }
    }
}


/*================================================
Achieved Section CSS
=================================================*/

.achieved-section {
    position: relative;
    background-color: #f9f9f9;
    z-index: 1;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/shape/shape9.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
        opacity: 0.8;
    }
    .achieved-title {
        text-align: center;
        margin-bottom: 50px;
        span {
            display: block;
            font-size: 16px;
            color: #ff8b00;
            font-weight: 400;
            margin-top: -5px;
            text-transform: capitalize;
        }
        h3 {
            font-size: 40px;
            margin: 10px 0 12px 0;
        }
        p {
            max-width: 750px;
            margin: auto;
        }
    }
    .fun-facts-content {
        overflow: hidden;
        text-align: center;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        transition: $transition;
        h3 {
            position: relative;
            color: #624272;
            margin-bottom: 5px;
            font: {
                size: 40px;
                weight: 700;
            }
            .sign-icon {
                display: inline-block;
                font-size: 30px;
                margin-left: 2px;
            }
        }
        p {
            line-height: initial;
            color: #6b6f76;
            margin: 0 0 0 0;
        }
        &:hover {
            transform: translateY(-10px);
        }
    }
}


/*================================================
History Section CSS
=================================================*/

.history-section {
    position: relative;
}

.history-content-area {
    max-width: 565px;
    margin-right: auto;
    span {
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        display: block;
        margin-top: -5px;
        text-transform: capitalize;
    }
    h3 {
        font-size: 38px;
        color: #624272;
        margin: 10px 0 18px 0;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
    }
    .history-btn {
        margin-top: 25px;
        .history-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #ff8b00;
            padding: 14px 25px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            border-radius: 3px;
            border: 1px solid #ff8b00;
            &:hover {
                background-color: $white-color;
                color: #ff8b00;
            }
        }
    }
}


/*================================================
Performance Section CSS
=================================================*/

.performance-section {
    position: relative;
    background-color: #f8f7f8;
}

.performance-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        display: block;
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        margin-top: -5px;
    }
    h3 {
        font-size: 40px;
        color: #624272;
        max-width: 720px;
        margin: auto;
    }
}

.single-performance {
    text-align: center;
    h3 {
        font-size: 18px;
        color: #3e435e;
        max-width: 240px;
        margin: 15px auto 0;
        line-height: 30px;
    }
    position: relative;
    .circlechart {
        width: 130px;
        height: 130px;
        margin-left: 55px;
        .circle-chart {
            width: 130px;
            height: 130px;
            .circle-chart__background {
                stroke: #a9b4c5;
                stroke-width: 0.8;
            }
            .circle-chart__circle {
                stroke: #ff8a00 !important;
                stroke-width: 1.8;
            }
            .circle-chart__info {
                font-weight: 600;
                .circle-chart__percent {
                    position: relative;
                }
            }
        }
    }
}


/*================================================
Productive Section CSS
=================================================*/

.productive-section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 70px;
    .col-lg-4.col-md-6.col-sm-6 {
        &:nth-child(1) {
            .productive-area-content {
                .icon {
                    i {
                        background-color: #ffe8cc;
                    }
                }
            }
        }
        &:nth-child(4) {
            .productive-area-content {
                .icon {
                    i {
                        background-color: #ffe8cc;
                    }
                }
            }
        }
        &:nth-child(3) {
            .productive-area-content {
                .icon {
                    i {
                        background-color: #ffd8d8;
                    }
                }
            }
        }
        &:nth-child(6) {
            .productive-area-content {
                .icon {
                    i {
                        background-color: #ffd8d8;
                    }
                }
            }
        }
    }
}

.productive-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        display: block;
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        margin-top: -5px;
        text-transform: capitalize;
    }
    h3 {
        font-size: 40px;
        margin: 10px 0 12px 0;
    }
    p {
        max-width: 750px;
        margin: auto;
    }
}

.productive-area-content {
    position: relative;
    padding-left: 75px;
    margin-bottom: 30px;
    .icon {
        i {
            display: inline-block;
            position: absolute;
            height: 60px;
            width: 60px;
            line-height: 60px;
            background-color: #eae7ec;
            color: #444444;
            text-align: center;
            left: 0;
            border-radius: 4px;
            &::before {
                font-size: 40px;
            }
        }
    }
    h3 {
        font-size: 20px;
        color: #444444;
        margin: 0 0 10px 0;
    }
    p {
        color: #827f8d;
        font-weight: 400;
        margin: 0 0 0 0;
    }
}


/*================================================
Needs Section CSS
=================================================*/

.needs-section {
    position: relative;
}

.needs-area-content {
    span {
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        display: block;
        margin-top: -5px;
    }
    h3 {
        font-size: 38px;
        color: #624272;
        margin: 10px 0 18px 0;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
    }
    .needs-text {
        margin-top: 25px;
        p {
            color: #464b54;
            margin: 0 0 0 0;
            position: relative;
            padding-left: 45px;
            &::before {
                position: absolute;
                content: "";
                height: 2px;
                width: 30px;
                left: 0;
                top: 16px;
                background-color: #ff8b00;
            }
        }
    }
    .needs-btn {
        margin-top: 30px;
        .needs-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #ff8b00;
            padding: 12px 25px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            border-radius: 3px;
            border: 1px solid #ff8b00;
            &:hover {
                background-color: $white-color;
                color: #ff8b00;
            }
        }
    }
}


/*================================================
Project Section CSS
=================================================*/

.project-section {
    position: relative;
}

.project-title {
    position: relative;
    margin-bottom: 45px;
    span {
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        display: block;
        margin-top: -5px;
    }
    h3 {
        font-size: 38px;
        color: #624272;
        margin: 10px 0 0 0;
        max-width: 510px;
    }
    .project-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        .project-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #ff8b00;
            padding: 12px 25px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            border-radius: 3px;
            border: 1px solid #ff8b00;
            &:hover {
                background-color: $white-color;
                color: #ff8b00;
            }
        }
    }
}

.single-project-item {
    position: relative;
    text-align: left;
    margin-bottom: 30px;
    .project-content {
        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
        padding: 25px 15px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: 0.6s;
        transition: 0.6s;
        padding: 25px;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: 5px;
            background-color: #ff8b00;
            z-index: -1;
            border-radius: 2px;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 5px;
            margin-top: 0px;
            color: $white-color;
            a {
                color: $white-color;
            }
        }
        p {
            color: #fce5ca;
            font-weight: 400;
        }
    }
    &:hover .project-content,
    .single-project-item:focus .project-content {
        bottom: 10px;
        opacity: 1;
        visibility: visible;
        color: $white-color;
    }
}


/*================================================
Saying Section CSS
=================================================*/

.saying-section {
    position: relative;
    background-color: #f8f8f8;
}

.saying-title {
    text-align: center;
    margin-bottom: 65px;
    h3 {
        font-size: 38px;
        color: #624272;
        margin: -8px 0 18px 0;
    }
    p {
        font-weight: 400;
        max-width: 750px;
        margin: auto;
    }
}

.saying-slides {
    .saying-single-item {
        position: relative;
        margin-top: 70px;
        transition: $transition;
        .saying-content-text {
            background-color: $white-color;
            padding: 30px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            h3 {
                font-size: 18px;
                color: #3e435e;
                margin: 0 0 12px 0;
            }
            p {
                color: #827f8d;
                margin: 0 0 0 0;
            }
        }
        .saying-info {
            margin-bottom: 16px;
            margin-top: 40px;
            padding-left: 100px;
            .say-image {
                position: absolute;
                left: 0;
                bottom: -15px;
                img {
                    display: inline-block;
                    width: 80px;
                    border-radius: 100px;
                }
            }
            h4 {
                font-size: 18px;
                color: #3e435e;
                margin: 0 0 2px 0;
            }
            span {
                color: #827f8d;
                font-size: 15px;
            }
        }
    }
    .owl-item {
        &.active {
            &.center .saying-single-item {
                margin-top: 0 !important;
            }
        }
    }
    &.owl-theme {
        .owl-dots {
            line-height: 0.01;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            margin: {
                top: 5px;
                bottom: 4px;
            }
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                    background-color: #dfdfdf;
                    transition: 0.6s;
                    border-radius: 50px;
                }
                &:hover {
                    span {
                        background-color: $main-color;
                    }
                }
                &.active {
                    span {
                        background-color: $main-color;
                    }
                }
            }
        }
        .owl-nav {
            margin-top: 30px;
        }
    }
}


/*================================================
Faq Section CSS
=================================================*/

.faq-section {
    position: relative;
}

.faq-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 16px;
        color: #ff8b00;
        font-weight: 400;
        display: block;
        margin-top: -5px;
    }
    h3 {
        font-size: 38px;
        color: #624272;
        margin: 10px 0 0 0;
    }
}

.faq-accordion {
    max-width: 790px;
    margin: auto;
    .accordion {
        list-style-type: none;
        padding: 0;
        margin: 0;
        padding-left: 0;
        margin-bottom: 0;
        position: relative;
        .accordion-item {
            display: block;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0;
            }
            .accordion-title {
                padding: 15px 20px;
                color: #624272;
                position: relative;
                background: transparent;
                border-radius: 2px;
                font-size: 18px;
                font-weight: 600;
                display: block;
                border: 1px solid #767c99;
                i {
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    color: #767c99;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    font-size: 15px;
                }
                &.active {
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                    background-color: $white-color;
                    i {
                        &::before {
                            content: "\f068";
                        }
                    }
                }
            }
            .accordion-content {
                display: none;
                background-color: $white-color;
                margin-bottom: 0;
                padding: 20px 15px;
                font-size: 15px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
                z-index: 1;
                position: relative;
                &.show {
                    display: block;
                }
            }
        }
    }
}


/*================================================
Provide Section CSS
=================================================*/

.provide-section {
    position: relative;
    z-index: 1;
    &::before {
        z-index: -1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/shape/shape7.png);
    }
}

.provide-area-content {
    h3 {
        font-size: 35px;
        color: #624272;
        margin: 0 0 12px 0;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
    }
    .provide-form {
        max-width: 550px;
        position: relative;
        margin: {
            left: auto;
            right: auto;
            top: 35px;
        }
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: #f1f1f1;
            border: none;
            height: 64px;
            padding-left: 25px;
            border-radius: 4px;
            padding-top: 0;
            outline: 0;
            color: $black-color;
            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 7px;
            top: 7px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            padding: 14px 30px;
            border-radius: 5px;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            text-transform: capitalize;
            font-size: 15px;
            font-weight: 400;
            font-size: 15px;
            font-weight: 400;
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
            i {
                margin-left: 5px;
            }
        }
    }
}


/*================================================
Startup Home Area CSS
=================================================*/

.startup-home-area {
    position: relative;
    height: 800px;
    z-index: 1;
    overflow: hidden;
    background-color: #f4f0f1;
    .hero-content {
        h1 {
            font-size: 60px;
            color: $black-color;
            font-weight: bold;
            margin: 20px 0 20px 0;
        }
        b {
            font-size: 60px;
            color: #002c8c;
            font-weight: bold;
            margin: 20px 0 20px 0;
        }
        p {
            color: #7d8087;
            font-weight: 500;
            margin: 0 0 0 0;
            font-size: 16px;
        }
        span {
            display: inline-block;
            font-size: 15px;
            color: $main-color;
            background-color: #eedbb8;
            padding: 8px 16px;
            font-weight: 400;
            border-radius: 30px;
            text-transform: capitalize;
        }
        .startup-btn {
            margin-top: 35px;
            .startup-btn-one {
                display: inline-block;
                color: $white-color;
                background-color: #002c8c;
                padding: 14px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 5px;
                border: 1px solid #002c8c;
                i {
                    position: relative;
                    top: 2px;
                    padding-left: 6px;
                    &::before {
                        font-size: 20px;
                    }
                }
                &:hover {
                    background-color: transparent;
                    color: #002c8c;
                    border: 1px solid #002c8c;
                }
            }
            .startup-btn-two {
                display: inline-block;
                color: #002c8c;
                border: 1px solid #002c8c;
                padding: 14px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 5px;
                margin-left: 15px;
                i {
                    position: relative;
                    top: 2px;
                    padding-left: 6px;
                    &::before {
                        font-size: 20px;
                    }
                }
                &:hover {
                    background-color: #002c8c;
                    color: $white-color;
                }
            }
        }
    }
    .startup-image {
        position: relative;
        img {
            position: absolute;
            top: -222px;
        }
    }
    .startup-shape {
        img {
            position: absolute;
            right: 230px;
            bottom: 0;
            -webkit-animation: movebounce 5s linear infinite;
            animation: movebounce 5s linear infinite;
            width: 850px;
            z-index: -1;
        }
    }
}


/*================================================
Partnar Two Area CSS
=================================================*/

.partnar-two-section {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #002c8c;
    .appreciate {
        max-width: 225px;
        margin-left: auto;
        h3 {
            color: $white-color;
            font-size: 18px;
            line-height: 1.4;
        }
    }
}


/*================================================
Solutions Section CSS
=================================================*/

.solutions-section {
    position: relative;
    .col-lg-4.col-md-6 {
        &:nth-child(1) {
            .solutions-area-content {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(../img/shape/shape10.png);
                    background-repeat: no-repeat;
                    background-position: top right;
                    z-index: -1;
                }
                .icon {
                    &::before {
                        background-color: #ffebe2;
                    }
                    i {
                        color: #fe5a0e;
                    }
                }
            }
        }
        &:nth-child(2) {
            .solutions-area-content {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(../img/shape/shape11.png);
                    background-repeat: no-repeat;
                    background-position: top right;
                    z-index: -1;
                }
                .icon {
                    &::before {
                        background-color: #e0f4ed;
                    }
                    i {
                        color: #00a86f;
                    }
                }
            }
        }
        &:nth-child(3) {
            .solutions-area-content {
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(../img/shape/shape12.png);
                    background-repeat: no-repeat;
                    background-position: top right;
                    z-index: -1;
                }
                .icon {
                    &::before {
                        background-color: #e0e5f1;
                    }
                    i {
                        color: #002c8c;
                    }
                }
            }
        }
    }
}

.solutions-title {
    text-align: center;
    margin-bottom: 50px;
    margin-top: -4px;
    span {
        display: inline-block;
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
        text-transform: capitalize;
    }
    h3 {
        font-size: 40px;
        margin: 10px 0 12px 0;
    }
    p {
        max-width: 750px;
        margin: auto;
    }
}

.solutions-area-content {
    position: relative;
    padding: 30px;
    transition: $transition;
    box-shadow: 0 13px 27px 0 rgba(197, 197, 197, 0.44);
    .icon {
        i {
            color: #012d8c;
            &::before {
                font-size: 60px;
                line-height: normal;
            }
        }
        position: relative;
        &::before {
            content: "";
            position: absolute;
            height: 80px;
            width: 50px;
            background-color: #e0e5f1;
            z-index: -1;
            left: -12px;
            top: -8px;
        }
    }
    h3 {
        font-size: 18px;
        margin: 20px 0 10px 0;
        line-height: 30px;
    }
    p {
        font-weight: 400;
        color: #827f8d;
        margin: 0 0 10px 0;
    }
    a {
        font-size: 16px;
        font-weight: 500;
        color: #827f8d;
        transition: $transition;
        &:hover {
            color: $main-color;
            letter-spacing: 1px;
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}


/*================================================
Circulate Section CSS
=================================================*/

.about-section {
    position: relative;
}

.about-content-area {
    span {
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        color: #343c4e;
        margin: 10px 0 18px 0;
    }
    p {
        font-weight: 400;
    }
    .about-btn {
        margin-top: 26px;
        .about-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #012d8c;
            padding: 10px 25px;
            font-size: 15px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid #012d8c;
            &:hover {
                background-color: $white-color;
                color: #012d8c;
            }
        }
    }
}


/*================================================
Co-operative Section CSS
=================================================*/

.co-operative-section {
    position: relative;
    z-index: 1;
    margin-bottom: -30px;
}

.co-operative-title {
    border-right: 1px solid #dfdddf;
    padding-right: 60px;
    margin-bottom: 50px;
    margin-top: -5px;
    span {
        color: #002c8c;
        font-size: 18px;
        display: block;
        margin-bottom: 10px;
        font-weight: 400;
    }
    h2 {
        margin-bottom: 0;
        color: #343c4e;
        font-size: 38px;
        font-weight: bold;
    }
}

.co-operative-text {
    padding-left: 40px;
    margin-bottom: 50px;
    p {
        color: #666666;
        margin-bottom: 0;
        line-height: 30px;
        font-weight: 400;
    }
}

.co-operative-content {
    text-align: center;
    background-image: url(../../assets/img/video-bg-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px;
    padding-bottom: 150px;
    .video-btn {
        display: inline-block;
        width: 90px;
        height: 90px;
        line-height: 100px;
        background-color: $white-color;
        border-radius: 50%;
        color: $main-color;
        position: relative;
        z-index: 1;
        i {
            font-size: 30px;
            position: relative;
            left: 4px;
            top: 2px;
        }
        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $white-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover,
        &:focus {
            background-color: $main-color;
            color: $white-color;
        }
    }
    h3 {
        font-size: 38px;
        margin: 50px 0 0 0;
        color: $white-color;
    }
}


/*================================================
Counter Section CSS
=================================================*/

.counter-area {
    position: relative;
    background-color: #002c8c;
    padding-top: 130px;
    padding-bottom: 70px;
    .single-fun-facts {
        overflow: hidden;
        text-align: center;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        transition: $transition;
        margin-bottom: 30px;
        h3 {
            position: relative;
            color: $white-color;
            margin-bottom: 10px;
            font: {
                size: 35px;
                weight: 700;
            }
            .sign-icon {
                display: inline-block;
                font-size: 30px;
                margin-left: 2px;
            }
        }
        p {
            line-height: initial;
            color: $white-color;
            margin: 0 0 0 0;
            font: {
                weight: 600;
            }
            padding-bottom: 0;
            position: relative;
            &::before {
                display: none;
            }
        }
        &:hover {
            transform: translateY(-10px);
        }
    }
}


/*================================================
Grow Section CSS
=================================================*/

.grow-section {
    position: relative;
}

.grow-area-content {
    position: relative;
    span {
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
        text-transform: capitalize;
    }
    h3 {
        font-size: 38px;
        color: #343c4e;
        margin: 10px 0 18px 0;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
        color: #464b54;
    }
    .grow-content-bar {
        position: relative;
        padding-left: 100px;
        margin-top: 45px;
        span {
            position: absolute;
            left: 0;
            height: 60px;
            width: 80px;
            display: inline-block;
            line-height: 60px;
            background-color: $main-color;
            text-align: center;
            color: $white-color;
            font-size: 20px;
            font-weight: bold;
            top: -10px;
        }
        h3 {
            font-size: 18px;
            color: #595d64;
            font-weight: 500;
            margin: 0 0 0 0;
        }
        &.bg-00a86f {
            span {
                position: absolute;
                left: 0;
                height: 60px;
                width: 80px;
                display: inline-block;
                line-height: 60px;
                background-color: #00a86f;
                text-align: center;
                color: $white-color;
                font-size: 20px;
                font-weight: bold;
                top: -10px;
            }
        }
    }
    .grow-btn {
        margin-top: 50px;
        .grow-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #012d8c;
            padding: 14px 30px;
            font-size: 16px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid #012d8c;
            &:hover {
                background-color: $white-color;
                color: #012d8c;
            }
        }
        .grow-btn-two {
            display: inline-block;
            color: #002c8c;
            background-color: transparent;
            padding: 14px 30px;
            font-size: 16px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid #012d8c;
            margin-left: 20px;
            &:hover {
                background-color: #002c8c;
                color: $white-color;
            }
        }
    }
}


/*================================================
Develop Section CSS
=================================================*/

.develop-section {
    position: relative;
}

.develop-content-area {
    span {
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
        text-transform: capitalize;
    }
    h3 {
        font-size: 38px;
        color: #343c4e;
        margin: 10px 0 0 0;
    }
    .develop-single-area {
        position: relative;
        margin-top: 45px;
        padding-left: 90px;
        .icon {
            i {
                display: inline-block;
                height: 65px;
                width: 65px;
                line-height: 65px;
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;
                background-color: $main-color;
                color: $white-color;
                &::before {
                    font-size: 40px;
                }
            }
        }
        h3 {
            font-size: 20px;
            color: #3e435e;
            margin: 0 0 12px 0;
        }
        p {
            color: #827f8d;
            font-weight: 400;
            margin: 0 0 0 0;
        }
        &.bg-00a86f {
            .icon {
                i {
                    display: inline-block;
                    height: 65px;
                    width: 65px;
                    line-height: 65px;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #00a86f;
                    color: $white-color;
                    &::before {
                        font-size: 40px;
                    }
                }
            }
        }
        &.bg-07328f {
            .icon {
                i {
                    display: inline-block;
                    height: 65px;
                    width: 65px;
                    line-height: 65px;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #07328f;
                    color: $white-color;
                    &::before {
                        font-size: 40px;
                    }
                }
            }
        }
    }
    .develop-btn {
        margin-top: 30px;
        .develop-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: transparent;
            padding: 14px 30px;
            font-size: 16px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            background-color: #002c8c;
            border: 1px solid #012d8c;
            &:hover {
                background-color: transparent;
                border: 1px solid #012d8c;
                color: #012d8c;
            }
        }
    }
}


/*================================================
Blog Section CSS
=================================================*/

.blog-section {
    position: relative;
    &.startup-blog {
        background-color: #fbfcff;
    }
}

.blog-title {
    position: relative;
    margin-bottom: 45px;
    margin-top: -5px;
    span {
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
        text-transform: capitalize;
    }
    h3 {
        font-size: 38px;
        color: #624272;
        margin: 10px 0 16px 0;
        max-width: 510px;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
        color: #6b6f76;
        max-width: 750px;
    }
    .blog-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        .blog-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: #002c8c;
            padding: 12px 25px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            border-radius: 3px;
            border: 1px solid #002c8c;
            &:hover {
                background-color: $white-color;
                color: #002c8c;
            }
        }
    }
}

.blog-item {
    transition: $transition;
    .single-blog-item {
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        background-color: $white-color;
        border-top: none;
        padding: 20px 25px;
        transition: $transition;
        position: relative;
        margin-bottom: 30px;
        .blog-content {
            transition: $transition;
            position: relative;
            h3 {
                color: #343846;
                font-size: 20px;
                margin: 0 0px 10px 0;
                line-height: 30px;
                transition: $transition;
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    height: 2px;
                    width: 60px;
                    background-color: #002c8c;
                    left: -54px;
                    top: 28px;
                    -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
            p {
                margin: 0;
                color: #6a6c72;
                font-weight: 400;
            }
            a {
                text-decoration: none;
            }
        }
        .blog-list {
            padding-left: 0;
            margin-bottom: 0;
            transition: $transition;
            margin-top: 15px;
            background-color: #f7f7f7;
            padding: 10px 15px;
            li {
                list-style-type: none;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                margin-right: 20px;
                color: #6b6f76;
                &:last-child {
                    margin-right: 0;
                }
                i {
                    &::before {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }
            }
            a {
                color: #93908f;
                text-decoration: none;
            }
            i {
                &::before {
                    font-size: 14px;
                    color: #93908f;
                    margin-right: 5px;
                }
            }
        }
    }
    &:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        .blog-content {
            h3 {
                color: #002c8c;
            }
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}


/*================================================
Subscribe Two Section CSS
=================================================*/

.job-opportunities {
    text-align: center;
    position: relative;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background-color: #002c8c;
        z-index: -1;
    }
    .section-title {
        max-width: 700px;
        margin: auto;
        span {
            background-color: transparent;
            display: block;
            margin-top: -12px;
            margin-bottom: 5px;
        }
        h3 {
            margin-bottom: 20px;
        }
    }
    .grow-btn {
        margin-top: 50px;
        position: relative;
        top: 55px;
        .grow-btn-one {
            display: inline-block;
            color: #012d8c;
            background-color: $white-color;
            padding: 14px 30px;
            font-size: 16px;
            font-weight: 400;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid $white-color;
            &:hover {
                background-color: transparent;
                color: $white-color;
                border: 1px solid $white-color;
            }
        }
        .grow-btn-two {
            display: inline-block;
            color: $white-color;
            background-color: transparent;
            padding: 14px 30px;
            font-size: 16px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid $white-color;
            margin-left: 20px;
            &:hover {
                background-color: $white-color;
                color: #012d8c;
            }
        }
    }
}


/*================================================
Subscribe Two Section CSS
=================================================*/

.subscribe-two-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 100px;
    background: {
        color: $main-color;
        image: url(../../assets/img/subscribe-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $main-color;
        left: 0;
        top: 0;
        opacity: 0.77;
        z-index: -1;
    }
    .subscribe-area-content {
        position: relative;
        z-index: 1;
    }
    .subscribe-content {
        text-align: center;
        max-width: 540px;
        margin: auto;
        .sub-title {
            color: $white-color;
            display: block;
            text-transform: capitalize;
            margin-bottom: 10px;
            font: {
                size: 18px;
                weight: 400;
            }
        }
        h2 {
            color: $white-color;
            max-width: 700px;
            margin: {
                bottom: 0;
                left: auto;
                right: auto;
            }
            font: {
                weight: 700;
                size: 38px;
            }
        }
        .newsletter-form {
            max-width: 830px;
            position: relative;
            margin: {
                left: auto;
                right: auto;
                top: 35px;
            }
            .input-newsletter {
                display: block;
                width: 100%;
                background-color: $white-color;
                border: none;
                height: 55px;
                padding-left: 25px;
                border-radius: 70px;
                padding-top: 5px;
                outline: 0;
                color: $black-color;
                &::placeholder {
                    color: $paragraph-color;
                }
            }
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                background-color: #e4045e;
                color: $white-color;
                border: none;
                height: 45px;
                padding: 0 30px;
                border-radius: 70px;
                transition: $transition;
                line-height: 45px;
                text-transform: capitalize;
                font: {
                    size: 16px;
                    weight: 500;
                }
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}

#validator-newsletter {
    color: red;
    margin-top: 10px;
}


/*================================================
SEO Agency Home Area CSS
=================================================*/

.seo-agency-home-area {
    position: relative;
    background-image: url(../../assets/img/seo-agency-bg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 900px;
    z-index: 1;
    padding-top: 130px;
    overflow: hidden;
    .hero-content {
        max-width: 800px;
        margin: auto;
        text-align: center;
        h1 {
            font-size: 50px;
            color: $white-color;
            font-weight: bold;
            margin: 0 0 20px 0;
        }
        p {
            color: $white-color;
            font-weight: 500;
            margin: 0 0 0 0;
            font-size: 16px;
        }
        .seo-btn {
            margin-top: 35px;
            .seo-btn-one {
                display: inline-block;
                color: $white-color;
                background-color: #ff8b00;
                padding: 14px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 3px;
                &:hover {
                    background-color: $white-color;
                    color: #ff8b00;
                }
            }
            .video-btn {
                animation-delay: 2.6s !important;
                display: inline-block;
                color: $white-color;
                position: relative;
                text-transform: capitalize;
                bottom: -3px;
                font-weight: 400;
                font-size: 16px;
                span {
                    display: inline-block;
                    margin-left: 40px;
                    width: 50px;
                    height: 50px;
                    line-height: 52px;
                    position: relative;
                    z-index: 1;
                    text-align: center;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.2);
                    color: $white-color;
                    font-size: 20px;
                    transition: 0.5s;
                    margin-right: 30px;
                    i {
                        position: relative;
                        top: 0px;
                        left: 2px;
                    }
                }
            }
            .video-btn span::after,
            .video-btn span::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, 0.2);
            }
            .video-btn span::before {
                -webkit-animation: ripple 2s linear infinite;
                animation: ripple 2s linear infinite;
            }
            .video-btn span::after {
                -webkit-animation: ripple 2s linear 1s infinite;
                animation: ripple 2s linear 1s infinite;
            }
            &:hover {
                span {
                    background-color: $white-color;
                    color: #130947;
                }
            }
        }
    }
    .seo-agency-image {
        text-align: center;
        margin-top: 60px;
        position: relative;
        img {
            max-width: 500px;
        }
    }
    @-webkit-keyframes ripple {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        75% {
            -webkit-transform: scale(1.75);
            transform: scale(1.75);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(2);
            transform: scale(2);
            opacity: 0;
        }
    }
    @keyframes ripple {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        75% {
            -webkit-transform: scale(1.75);
            transform: scale(1.75);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(2);
            transform: scale(2);
            opacity: 0;
        }
    }
    .seo-shape {
        position: absolute;
        bottom: 0;
        left: 5px;
        width: 100%;
        height: auto;
        z-index: -1;
    }
    .nav-shape {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}


/*================================================
Visitor Section CSS
=================================================*/

.visitor-section {
    position: relative;
    padding-top: 60px;
    .col-lg-3.col-md-6 {
        &:nth-child(1) {
            .visitor-content-area {
                margin-top: 30px;
            }
        }
        &:nth-child(3) {
            .visitor-content-area {
                margin-top: 30px;
            }
        }
    }
}

.visitor-title {
    margin-bottom: 50px;
    h2 {
        margin-bottom: 0;
        color: #3f3360;
        font-size: 38px;
        font-weight: bold;
    }
}

.visitor-text {
    margin-bottom: 50px;
    p {
        color: #827f8d;
        margin-bottom: 0;
        line-height: 30px;
        font-weight: 400;
    }
}

.visitor-content-area {
    position: relative;
    padding: 20px;
    transition: 0.5s;
    box-shadow: 0px 0px 27px 0 rgba(197, 197, 197, 0.44);
    margin-bottom: 30px;
    transition: $transition;
    .icon {
        i {
            color: $main-color;
            &::before {
                font-size: 50px;
                line-height: normal;
            }
        }
    }
    h3 {
        font-size: 18px;
        color: #3e435e;
        margin: 14px 0 5px 0;
    }
    p {
        color: #827f8d;
        margin: 0 0 0 0;
    }
    .visitor-shape {
        position: absolute;
        top: 0;
        right: 0;
    }
    &.bg-00a86f {
        .icon {
            i {
                color: #00a86f;
                &::before {
                    font-size: 50px;
                    line-height: normal;
                }
            }
        }
    }
    &.bg-302353 {
        .icon {
            i {
                color: #302353;
                &::before {
                    font-size: 50px;
                    line-height: normal;
                }
            }
        }
    }
    &.bg-c53a17 {
        .icon {
            i {
                color: #c53a17;
                &::before {
                    font-size: 50px;
                    line-height: normal;
                }
            }
        }
    }
    &:hover {
        transform: translateY(-30px);
    }
}


/*================================================
Specialty Section CSS
=================================================*/

.specialty-section {
    position: relative;
}

.specialty-content-area {
    span {
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        color: #3f3360;
        margin: 10px 0 14px 0;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
        color: #6b6f76;
    }
    .specialty-list {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: 0px;
        margin-top: 10px;
        li {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            font-weight: 500;
            color: #3e435e;
            font-size: 18px;
            padding-right: 0;
            padding-left: 0;
            margin-top: 20px;
            position: relative;
        }
        i {
            display: inline-block;
            height: 25px;
            width: 32px;
            line-height: 25px;
            background-color: #11023b;
            color: $white-color;
            text-align: center;
            font-size: 12px;
            margin-right: 8px;
            border-radius: 3px 0 3px 3px;
        }
    }
    .specialty-btn {
        margin-top: 40px;
        .specialty-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: $main-color;
            padding: 14px 25px;
            font-size: 16px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid $main-color;
            &:hover {
                background-color: $white-color;
                color: $main-color;
            }
        }
    }
}


/*================================================
Department Section CSS
=================================================*/

.department-area-content {
    span {
        font-size: 16px;
        color: #002c8c;
        font-weight: 400;
    }
    h3 {
        font-size: 38px;
        color: #3f3360;
        margin: 10px 0 14px 0;
    }
    p {
        font-weight: 400;
        margin: 0 0 0 0;
        color: #6b6f76;
    }
    .department-list {
        margin-bottom: 0;
        padding-left: 0;
        li {
            list-style-type: none;
            font-weight: 500;
            color: #3e435e;
            font-size: 18px;
            margin-top: 20px;
            position: relative;
        }
        i {
            display: inline-block;
            height: 25px;
            width: 32px;
            line-height: 25px;
            background-color: #11023b;
            color: $white-color;
            text-align: center;
            font-size: 12px;
            margin-right: 8px;
            border-radius: 3px 0 3px 3px;
        }
    }
    .department-btn {
        margin-top: 40px;
        .department-btn-one {
            display: inline-block;
            color: $white-color;
            background-color: $main-color;
            padding: 14px 25px;
            font-size: 16px;
            font-weight: 400;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            border-radius: 3px;
            border: 1px solid $main-color;
            &:hover {
                background-color: $white-color;
                color: $main-color;
            }
        }
    }
}


/*================================================
Success Section CSS
=================================================*/

.success-section {
    background-color: #150346;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: $white-color;
        border-radius: 48%;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: $white-color;
        border-radius: 48%;
    }
}

.success-title {
    text-align: center;
    margin-bottom: 50px;
    h3 {
        font-size: 38px;
        color: $white-color;
        margin: 0 0 0 0;
    }
}

.success-area-content {
    position: relative;
    padding-left: 52px;
    i {
        position: absolute;
        left: 0;
        top: -6px;
        color: $white-color;
        &::before {
            font-size: 40px;
            margin-left: 0;
        }
    }
    h3 {
        font-size: 22px;
        color: $white-color;
        margin: 0 0 5px 0;
    }
    span {
        font-size: 16px;
        color: #c8b9f0;
    }
}


/*================================================
Partnar Three Area CSS
=================================================*/

.partnar-three-section {
    position: relative;
    padding-bottom: 0;
    .owl-carousel {
        .owl-item {
            img {
                width: unset;
                margin: auto;
                opacity: 0.5;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}


/*================================================
Offer Section CSS
=================================================*/

.offer-section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 70px;
}

.offer-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        color: #3e435e;
        font-weight: 400;
        display: block;
        margin-top: -5px;
        text-transform: capitalize;
    }
    h3 {
        font-size: 36px;
        margin: 5px 0 10px 0;
        color: #3f3360;
    }
    p {
        color: #827f8d;
        font-weight: 400;
        max-width: 750px;
        margin: auto;
    }
}

.products-area-content {
    box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
    padding: 40px;
    margin-bottom: 30px;
    position: relative;
    transition: all 0.5s;
    .products-image {
        text-align: center;
    }
    h3 {
        font-size: 20px;
        color: #3e435e;
        margin: 40px 0 10px 0;
    }
    p {
        color: #827f8d;
        font-weight: 400;
        margin: 0 0 10px 0;
    }
    .products-btn-one {
        display: inline-block;
        font-size: 16px;
        color: #fe5a0e;
        font-weight: 500;
        transition: $transition;
        &:hover {
            letter-spacing: 1px;
        }
    }
    .products-text {
        span {
            position: absolute;
            font-size: 30px;
            color: #827f8d;
            font-weight: 400;
            right: 16px;
            bottom: 4px;
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}


/*================================================
Increase Section CSS
=================================================*/

.increase-section {
    padding-top: 100px;
    padding-bottom: 70px;
    background-color: #150346;
    position: relative;
    z-index: 1;
    .shape {
        position: absolute;
        top: 150px;
        left: 30px;
        width: 50%;
        z-index: -1;
    }
    &::before {
        content: "";
        position: absolute;
        top: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: $white-color;
        border-radius: 48%;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: $white-color;
        border-radius: 48%;
    }
}

.increase-title {
    position: relative;
    margin-bottom: 60px;
    span {
        font-size: 16px;
        color: #c8b9f0;
        font-weight: 400;
        display: block;
        margin-top: -5px;
        text-transform: capitalize;
    }
    h3 {
        font-size: 38px;
        color: $white-color;
        margin: 10px 0 16px 0;
        max-width: 510px;
    }
    .increase-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        .increase-btn-one {
            display: inline-block;
            color: #030749;
            background-color: $white-color;
            padding: 12px 25px;
            font-size: 16px;
            font-weight: 400;
            transition: $transition;
            border-radius: 3px;
            border: 1px solid $white-color;
            &:hover {
                background-color: $main-color;
                color: $white-color;
                border: 1px solid $main-color;
            }
        }
    }
}

.increase-item {
    position: relative;
    .increase-area-content {
        padding-left: 100px;
        margin-bottom: 30px;
        img {
            position: absolute;
            left: 16px;
            top: 18px;
            z-index: 1;
        }
        h3 {
            font-size: 20px;
            color: #d9cdff;
            margin: 0 0 10px 0;
        }
        p {
            color: #d9cdff;
            margin: 0 0 0 0;
            font-weight: 400;
        }
    }
    .increase-shape {
        img {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}


/*================================================
Team Section CSS
=================================================*/

.team-section {
    position: relative;
    background-color: #fafafa;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: $white-color;
        border-radius: 48%;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: $white-color;
        border-radius: 48%;
    }
    .shape {
        position: absolute;
        top: 125px;
        left: 0;
        width: 100%;
        right: 0;
        margin: auto;
        z-index: -1;
    }
}

.team-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 15px;
        color: #3e435e;
        font-weight: 400;
        display: block;
        margin-top: -5px;
    }
    h3 {
        font-size: 36px;
        margin: 5px 0 10px 0;
        color: #3f3360;
    }
    p {
        color: #827f8d;
        font-weight: 400;
        max-width: 750px;
        margin: auto;
    }
}

.single-team-member {
    text-align: center;
    margin-bottom: 30px;
    .member-image {
        position: relative;
        border-radius: 50%;
        img {
            border-radius: 50%;
        }
        .details-btn {
            border-radius: 50%;
        }
    }
    .member-content {
        margin-top: 20px;
        h3 {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 700;
        }
        a {
            color: #3e435e;
        }
        span {
            display: block;
            color: #827f8d;
            margin-top: 8px;
            font-size: 18px;
        }
    }
    &:hover .member-image {
        &::before {
            opacity: 0.6;
            visibility: visible;
        }
        .details-btn {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
        }
    }
}


/*================================================
Request Section CSS
=================================================*/

.request-section {
    position: relative;
}

.request-area {
    background: $white-color;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 30px;
    border-radius: 5px;
    h3 {
        font-size: 25px;
        color: #3f3360;
        margin: 0 0 8px 0;
    }
    .request-form {
        .form-group {
            margin-bottom: 20px;
        }
        form {
            .form-control {
                height: 55px;
                padding: 10px;
                font-size: 15px;
                color: #0e314c;
                border: 1px solid #f8f8f8;
                border-radius: 0;
                background: #f8f8f8;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
            .btn {
                font-weight: 400;
                font-size: 16px;
                border: none;
                padding: 15px 40px;
                position: relative;
                border-radius: 4px;
                z-index: 1;
                text-transform: capitalize;
                transition: 0.5s;
                width: 100%;
            }
            .btn-primary {
                color: $white-color;
                background-color: $main-color;
                -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
                box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
                transition: $transition;
                border: 1px solid $main-color;
                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
    }
}


/*================================================
Blog Two Section CSS
=================================================*/

.blog-two-section {
    position: relative;
    padding-bottom: 70px;
    .blog-title-two {
        position: relative;
        margin-bottom: 45px;
        text-align: center;
        span {
            font-size: 16px;
            color: #002c8c;
            font-weight: 400;
        }
        h3 {
            font-size: 38px;
            color: #624272;
            margin: 10px 0 0 0;
        }
        .blog-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            .blog-btn-one {
                display: inline-block;
                color: $white-color;
                background-color: #002c8c;
                padding: 12px 25px;
                font-size: 16px;
                font-weight: 400;
                transition: $transition;
                border-radius: 3px;
                border: 1px solid #002c8c;
                &:hover {
                    background-color: $white-color;
                    color: #002c8c;
                }
            }
        }
    }
    .blog-item-two {
        transition: $transition;
        .single-blog-item {
            -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            background-color: $white-color;
            border-top: none;
            padding: 20px 25px;
            transition: $transition;
            position: relative;
            margin-bottom: 30px;
            .blog-content {
                transition: $transition;
                position: relative;
                h3 {
                    color: #343846;
                    font-size: 20px;
                    margin: 0 0px 10px 0;
                    line-height: 30px;
                    transition: $transition;
                    position: relative;
                }
                p {
                    margin: 0;
                    color: #6a6c72;
                    font-weight: 400;
                }
                a {
                    text-decoration: none;
                }
            }
            .blog-list {
                padding-left: 0;
                margin-bottom: 0;
                transition: $transition;
                margin-top: 15px;
                background-color: #f7f7f7;
                padding: 10px 15px;
                li {
                    list-style-type: none;
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    margin-right: 20px;
                    color: #6b6f76;
                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        &::before {
                            font-size: 14px;
                            margin-right: 5px;
                        }
                    }
                }
                a {
                    color: #93908f;
                    text-decoration: none;
                }
                i {
                    &::before {
                        font-size: 14px;
                        color: #93908f;
                        margin-right: 5px;
                    }
                }
            }
        }
        &:hover {
            -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            .blog-content {
                h3 {
                    color: #002c8c;
                }
            }
        }
    }
}


/*================================================
Update Section CSS
=================================================*/

.update-section {
    position: relative;
    background-color: #150346;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: #ffffff;
        border-radius: 48%;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -47px;
        left: 0;
        width: 100%;
        height: 100px;
        background-color: #ffffff;
        border-radius: 48%;
    }
    .shape {
        width: 25%;
        position: absolute;
        left: 100px;
        top: 57px;
        z-index: -1;
    }
    .shape-2 {
        width: 20%;
        position: absolute;
        right: 50px;
        top: 57px;
        z-index: -1;
    }
}

.update-title {
    text-align: center;
    margin-bottom: 30px;
    h3 {
        font-size: 38px;
        color: $white-color;
        margin: -8px 0 0 0;
    }
}

.update-form {
    max-width: 630px;
    position: relative;
    margin: {
        left: auto;
        right: auto;
        top: 35px;
    }
    .input-newsletter {
        display: block;
        width: 100%;
        background-color: transparent;
        border: none;
        height: 60px;
        padding-left: 25px;
        border-radius: 5px;
        padding-top: 0;
        outline: 0;
        color: $white-color;
        border: 1px solid #534677;
        &::placeholder {
            color: $paragraph-color;
        }
    }
    button {
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: #fe5a0e;
        color: $white-color;
        border: none;
        height: 50px;
        padding: 0 20px;
        border-radius: 5px;
        transition: 0.5s;
        line-height: 45px;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
        &:hover {
            background-color: $optional-color;
            color: $main-color;
        }
    }
}


/*================================================
Page Title Area CSS
=================================================*/

.page-title-area {
    position: relative;
    z-index: 1;
    background-image: url(../../assets/img/bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #000000;
        left: 0;
        top: 0;
        opacity: 0.7;
        z-index: -1;
    }
}

.page-title-content {
    text-align: center;
    margin-top: 75px;
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 42px;
            weight: 700;
        }
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 12px;
            bottom: 0;
        }
        li {
            color: $white-color;
            display: inline-block;
            position: relative;
            font: {
                weight: 400;
                size: 17px;
            }
            margin: {
                left: 12px;
                right: 12px;
            }
            a {
                display: inline-block;
                color: $white-color;
            }
            &::before {
                content: "";
                position: absolute;
                right: -15px;
                top: 11px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $main-color;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}


/*================================================
Services Details CSS
=================================================*/

.services-details-overview {
    margin-bottom: 60px;
    &:last-child {
        margin-bottom: 0;
    }
    .services-details-desc {
        h3 {
            margin-bottom: 15px;
            font-size: 24px;
            font-weight: 600;
        }
        .features-text {
            margin-top: 25px;
            h4 {
                margin-bottom: 15px;
                font-size: 17px;
                font-weight: 600;
                i {
                    font-size: 15px;
                    margin-right: 4px;
                    color: $main-color;
                }
            }
        }
        .services-details-accordion {
            margin-top: 25px;
            .accordion {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
                .accordion-item {
                    display: block;
                    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                    background: $white-color;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        text-decoration: none;
                    }
                }
                .accordion-title {
                    padding: 15px 20px 15px 51px;
                    color: #000000;
                    position: relative;
                    border-bottom: 1px solid transparent;
                    margin-bottom: -1px;
                    display: inline-block;
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        text-align: center;
                        height: 100%;
                        background: $main-color;
                        color: $white-color;
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 13px;
                        }
                    }
                    &.active {
                        border-bottom-color: #eeeeee;
                        i {
                            &::before {
                                content: "\f068";
                            }
                        }
                    }
                }
                .accordion-content {
                    display: none;
                    position: relative;
                    padding: 15px 15px;
                    &.show {
                        display: block;
                    }
                }
            }
        }
    }
}


/*================================================
Pagination Area CSS
=================================================*/

.pagination-area {
    margin-top: 20px;
    text-align: center;
    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 48px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font: {
            size: 18px;
            weight: 700;
        }
        &.current,
        &:hover,
        &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}


/*================================================
Projects Details Area CSS
=================================================*/

.project-details-image {
    margin-bottom: 30px;
}

.projects-details-desc {
    margin-top: 5px;
    h3 {
        margin-bottom: 13px;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    .features-text {
        margin: {
            top: 25px;
            bottom: 25px;
        }
        h4 {
            margin-bottom: 12px;
            font: {
                size: 17px;
                weight: 600;
            }
            i {
                font-size: 16px;
                margin-right: 4px;
                color: $main-color;
            }
        }
    }
    .project-details-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            right: -15px;
            left: -15px;
            top: 35px;
        }
        .single-info-box {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 15px;
                right: 15px;
            }
            h4 {
                margin-bottom: 10px;
                font: {
                    size: 19px;
                    weight: 700;
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                font-size: 15px;
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    display: inline-block;
                    margin-right: 8px;
                    a {
                        color: $paragraph-color;
                        display: inline-block;
                        &:hover {
                            color: $main-color;
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}


/*================================================
FAQ Area CSS
=================================================*/

.faq-contact-section {
    position: relative;
    .faq-title {
        text-align: center;
        margin-bottom: 50px;
        h2 {
            font-size: 38px;
            color: #624272;
            margin: 0 0 10px 0;
        }
        p {
            font-weight: 400;
            max-width: 750px;
            margin: auto;
        }
    }
}

.faq-contact-form {
    max-width: 850px;
    margin: 0 auto;
    text-align: center;
    form {
        .form-control {
            background-color: $white-color;
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
            height: 50px;
            font-size: 15px;
        }
        textarea.form-control {
            height: 150px;
            padding-top: 15px;
            line-height: initial;
        }
        .faq-form-btn {
            margin-top: 20px;
        }
    }
}


/*================================================
Login In Style CSS
=================================================*/

.login-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;
    .login-title {
        text-align: center;
        margin-bottom: 30px;
        h3 {
            font: {
                size: 25px;
                weight: 700;
            }
            margin-bottom: 8px;
            color: #343846;
        }
        p {
            margin-bottom: 30px;
        }
    }
    form {
        .form-control {
            height: 50px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: #292a2c;
            padding-left: 20px;
            background: transparent;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }
            &::placeholder {
                color: #666666;
            }
        }
        .form-check {
            margin-top: 18px;
            .form-check-label {
                color: #666666;
                position: relative;
                top: -2px;
            }
        }
        .form-check-input {
            margin-top: 3px;
        }
        .send-btn {
            margin: {
                top: 20px;
            }
            .default-btn-one {
                display: inline-block;
                padding: 12px 30px;
                background: $main-color;
                border: 1px solid $main-color;
                color: $white-color;
                font-size: 16px;
                font-weight: 400;
                transition: 0.6s;
                &:hover {
                    text-decoration: none;
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
        span {
            display: block;
            color: #777777;
            position: absolute;
            bottom: 22px;
            right: 15px;
            a {
                color: $main-color;
            }
        }
    }
    .forgot-password {
        margin-bottom: 0;
        margin-top: -25px;
        a {
            color: #777777;
            &:hover {
                color: $main-color;
            }
        }
    }
}


/*================================================
Sign Up Style CSS
=================================================*/

.signup-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;
    h3 {
        font: {
            size: 25px;
            weight: 700;
        }
        margin-bottom: 30px;
        text-align: center;
    }
    form {
        .form-control {
            height: 50px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: #292a2c;
            padding-left: 20px;
            background: transparent;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }
            &::placeholder {
                color: #666666;
            }
        }
        .form-check {
            margin-top: 18px;
            .form-check-label {
                color: #666666;
                position: relative;
                top: -2px;
            }
        }
        .send-btn {
            margin: {
                top: 20px;
            }
            .default-btn-one {
                display: inline-block;
                padding: 12px 30px;
                background: $main-color;
                border: 1px solid $main-color;
                color: $white-color;
                font-size: 16px;
                font-weight: 400;
                transition: 0.6s;
                &:hover {
                    text-decoration: none;
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
        span {
            display: block;
            color: #666666;
            position: absolute;
            bottom: 35px;
            right: 15px;
            a {
                color: $main-color;
            }
        }
    }
}
.iti__country span{
    position: unset !important;
}

/*================================================
404 Error Area CSS
=================================================*/

.error-area {
    height: 100vh;
}

.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    h3 {
        font: {
            size: 35px;
            weight: 700;
        }
        margin: {
            top: 30px;
            bottom: 18px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
        line-height: 30px;
    }
    .default-btn-one {
        display: inline-block;
        padding: 12px 30px;
        background: $main-color;
        border: 1px solid $main-color;
        color: $white-color;
        font-size: 16px;
        font-weight: 400;
        transition: 0.6s;
        &:hover {
            text-decoration: none;
            background-color: $white-color;
            color: $main-color;
        }
    }
}


/*================================================
Coming Soon Area CSS
=================================================*/

.coming-soon-area {
    position: relative;
    z-index: 1;
    height: 100vh;
    background: {
        image: url(../../assets/img/marketing-agency-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &::before {
        z-index: -1;
        background: #000000;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 0.8;
    }
    .social-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        bottom: 30px;
        li {
            display: inline-block;
            &.list-heading {
                display: block;
                color: $white-color;
                margin-bottom: 10px;
                font: {
                    size: 16px;
                    weight: 500;
                }
            }
            a {
                width: 35px;
                height: 35px;
                line-height: 35px;
                background: #f45a43;
                border: 1px solid #f45a43;
                color: $white-color;
                border-radius: 50%;
                margin: 0 2px;
                display: inline-block;
                &:hover {
                    background-color: transparent;
                    color: #f45a43;
                    transition: 0.6s;
                    -webkit-transition: 0.5s;
                }
            }
        }
    }
}

.coming-soon-content {
    text-align: center;
    max-width: 820px;
    margin: -80px auto 0;
    h1 {
        color: $white-color;
        margin-bottom: 15px;
        font: {
            size: 46px;
            weight: 700;
        }
    }
    p {
        color: $white-color;
        margin: 0 auto;
        max-width: 620px;
        line-height: 30px;
    }
    form {
        position: relative;
        margin: 35px auto 55px;
        max-width: 520px;
        .email-input {
            display: block;
            width: 100%;
            height: 56px;
            border: none;
            box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
            border-radius: 40px;
            padding: 15px 25px;
            outline: 0 !important;
            background: #f1f2f3;
        }
        .submit-btn {
            position: absolute;
            right: 3px;
            top: 3px;
            height: 50px;
            background: #f45a43;
            color: $white-color;
            border: none;
            border-radius: 40px;
            width: 130px;
            outline: 0 !important;
            cursor: pointer;
            text-transform: uppercase;
            font: {
                size: 14px;
                weight: 600;
            }
            &:hover,
            &:focus {
                background-color: #ff3547;
            }
        }
    }
    #timer {
        div {
            background: $white-color;
            display: inline-block;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            color: #ff3547;
            box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
            margin: 0 10px;
            padding-top: 18px;
            font: {
                size: 40px;
                weight: 700;
            }
            span {
                display: block;
                margin-top: -4px;
                color: #777777;
                font: {
                    size: 14px;
                    weight: 600;
                }
            }
        }
    }
}


/*================================================
Contact Section CSS
=================================================*/

.contact-area {
    .contact-form {
        box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
        padding: 30px;
        form {
            text-align: center;
            .form-control {
                background-color: $white-color;
                border: none;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
                height: 57px;
                color: $main-color;
                border: 1px solid $white-color;
                &:focus {
                    border: 1px solid $main-color;
                }
            }
            .form-group {
                text-align: left;
                margin-bottom: 25px;
            }
            textarea.form-control {
                height: 250px;
                padding-top: 15px;
            }
        }
        .default-btn {
            cursor: pointer;
            margin-top: 10px;
        }
        .text-danger {
            color: #dc3545 !important;
            margin-bottom: 0 !important;
            margin-top: 20px !important;
        }
        .list-unstyled {
            margin-top: 10px;
            color: red;
        }
    }
}

.contact-box {
    padding-top: 100px;
    padding-bottom: 35px;
}

.single-contact-box {
    text-align: center;
    background-color: $white-color;
    box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
    padding: 30px;
    margin-bottom: 30px;
    i {
        line-height: 50px;
        width: 50px;
        height: 50px;
        border: 1px dashed $main-color;
        border-radius: 50%;
        font-size: 20px;
        display: inline-block;
        color: $main-color;
        transition: all 0.5s;
        margin-bottom: 20px;
    }
    &:hover {
        i {
            background-color: $main-color;
            color: $white-color;
        }
    }
    .content-title {
        h3 {
            font-size: 25px;
            margin-bottom: 10px;
        }
        a {
            display: block;
            font-size: 16px;
            color: #747171;
            margin-bottom: 3px;
            &:hover {
                color: $main-color;
            }
        }
    }
}


/*================================================
Blog Details Area CSS
=================================================*/

.blog-details-desc {
    .article-content {
        margin-top: 30px;
        .entry-meta {
            margin-bottom: -8px;
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 21px;
                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;
                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 25px;
            }
            font: {
                size: 24px;
                weight: 600;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    -ms-flex: 0 0 33.3333%;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;
                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 40px;
                    background-color: #faf5f5;
                    color: $main-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        }
        .article-tags {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;
                &:hover {
                    color: $main-color;
                }
            }
        }
        .article-share {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                }
                li {
                    display: inline-block;
                    span {
                        display: inline-block;
                        margin-right: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $main-color;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
                        font-size: 12px;
                        margin-left: 5px;
                        &:hover {
                            color: $white-color;
                            background-color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .post-navigation {
        margin-top: 30px;
    }
}

blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 24px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f10e";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}

.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .nav-previous {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            a {
                i {
                    margin-right: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
            a {
                i {
                    margin-left: 2px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                font: {
                    weight: 600;
                }
            }
        }
    }
}

.comments-area {
    padding: 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
    background-color: $white-color;
    .comments-title {
        position: relative;
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 22px;
            weight: 600;
        }
    }
    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        .reply {
            margin-top: 15px;
            a {
                border: 1px solid #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: capitalize;
                position: relative;
                z-index: 1;
                font: {
                    size: 12px;
                    weight: 600;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 17px;
        margin-bottom: 0.1em;
        position: relative;
        z-index: 2;
        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 600;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: 0.8em;
        color: $paragraph-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        }
        a {
            color: $paragraph-color;
            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;
        .comment-reply-title {
            margin-bottom: 0;
            position: relative;
            font: {
                size: 20px;
                weight: 600;
            }
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 0;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            margin-top: 20px;
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        label {
            display: block;
            font-weight: 600;
            color: $black-color;
            margin-bottom: 5px;
        }
        input[type="date"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="week"],
        input[type="month"],
        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="number"],
        textarea {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: $transition;
            &:focus {
                border-color: $main-color;
            }
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;
            input {
                position: absolute;
                left: 0;
                top: 4px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
                font-weight: normal;
                position: relative;
                top: -2px;
            }
        }
        .form-submit {
            float: left;
            width: 100%;
            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 10px 30px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: uppercase;
                transition: $transition;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &:hover,
                &:focus {
                    color: $white-color;
                    background-color: $black-color;
                }
            }
        }
    }
}


/*================================================
Sidebar Widget Area CSS
=================================================*/

.widget-area {
    .widget {
        margin-top: 35px;
        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 25px;
            text-transform: capitalize;
            position: relative;
            font: {
                weight: 600;
                size: 22px;
            }
            &::before {
                content: "";
                position: absolute;
                background: $main-color;
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 15px;
        form {
            position: relative;
            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                &:focus {
                    border-color: $main-color;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_zash_posts_thumb {
        position: relative;
        overflow: hidden;
        .item {
            overflow: hidden;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;
                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(../../assets/img/blog-details/img1.jpg);
                    }
                    &.bg2 {
                        background-image: url(../../assets/img/blog-details/img2.jpg);
                    }
                    &.bg3 {
                        background-image: url(../../assets/img/blog-details/img3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;
                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 5px;
                        bottom: 3px;
                    }
                    font: {
                        size: 12px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 14px;
                        weight: 500;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 400;
                    size: 15.5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
                .post-date {
                    display: block;
                    font-size: 13px;
                    color: $paragraph-color;
                    margin-top: 4px;
                }
            }
        }
    }
    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                line-height: 1.5;
                font: {
                    weight: 400;
                    size: 15.5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 8px;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 14px;
                color: $black-color;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;
                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    float: right;
                }
            }
        }
    }
    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $black-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 400;
                size: 14.5px !important;
            }
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover,
            &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .widget_event_details {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                border-bottom: 1px solid #eeeeee;
                color: #888f96;
                padding: {
                    bottom: 10px;
                    top: 10px;
                }
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                span {
                    display: inline-block;
                    color: $black-color;
                    font-weight: 400;
                }
                a {
                    display: inline-block;
                    color: #888f96;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}


/*================================================
Shop Area CSS
=================================================*/

.woocommerce-topbar {
    margin-bottom: 40px;
    .woocommerce-topbar-ordering {
        text-align: right;
        .nice-select {
            background-color: $white-color;
            color: #666666;
            border-radius: 0;
            border: none;
            box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
            float: unset;
            height: unset;
            line-height: initial;
            padding: 17px 35px 13px 20px;
            font: {
                size: 16px;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0 0 29px 0 rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: 0.6s;
                    font: {
                        size: 16px;
                    }
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 700;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
}

.single-product-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    .product-image {
        overflow: hidden;
        position: relative;
        text-align: left;
        a {
            img {
                transition: 0.6s;
            }
        }
        .add-to-cart-btn {
            position: absolute;
            bottom: -46px;
            left: 0;
            width: 100%;
            background-color: #000000;
            color: $white-color;
            padding: 18px 20px 13px;
            text-transform: uppercase;
            opacity: 0;
            visibility: hidden;
            text-align: center;
            font: {
                size: 15px;
                weight: 700;
            }
            i {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
        .sale-btn {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: $main-color;
            color: $white-color;
            width: 55px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            border-radius: 50%;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            }
        }
    }
    .product-content {
        padding: 30px 25px;
        h3 {
            margin-bottom: 12px;
            font: {
                size: 20px;
                weight: 700;
            }
            a {
                display: inline-block;
                color: $main-color;
                text-decoration: none;
            }
        }
        .price {
            color: #000000;
            font: {
                weight: 600;
                size: 15px;
            }
            .old {
                text-decoration: line-through;
                color: #828893;
                font: {
                    size: 14px;
                }
            }
        }
        .rating {
            margin-top: 12px;
            color: #ffb708;
            font: {
                size: 15px;
            }
            i {
                margin-right: -1px;
            }
        }
    }
    &:hover {
        .product-image {
            .add-to-cart-btn {
                bottom: 0;
                opacity: 1;
                visibility: visible;
                transition: 0.6s;
                text-decoration: none;
            }
        }
    }
}


/*================================================
Product Details Area CSS
=================================================*/

.product-details-desc {
    h3 {
        margin-bottom: 12px;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    .price {
        margin-bottom: 10px;
        color: #000000;
        font: {
            size: 15px;
            weight: 600;
        }
        .old-price {
            text-decoration: line-through;
            color: #828893;
        }
    }
    .product-review {
        margin-bottom: 15px;
        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;
            i {
                color: #ffba0a;
            }
        }
        .rating-count {
            display: inline-block;
            color: #000000;
            border-bottom: 1px solid #000000;
            line-height: initial;
            &:hover {
                color: $main-color;
                border-color: $main-color;
                text-decoration: none;
            }
        }
    }
    .product-add-to-cart {
        margin-top: 20px;
        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;
            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 50px;
                height: 100%;
                line-height: 55px;
                transition: 0.6s;
                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 50px;
                color: #000000;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: #000000;
                }
            }
        }
        .default-btn {
            position: relative;
            border: none;
            padding: 12px 30px;
            background-color: $main-color;
            color: $white-color;
            border: 1px solid $main-color;
            i {
                margin-right: 2px;
            }
            &:hover {
                background-color: $white-color;
                color: $main-color;
                transition: 0.6s;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;
        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);
                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: 0.6s;
                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: 0.6s;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: #000000;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: 0.6s;
                    }
                }
                &:last-child {
                    position: relative;
                    top: 2px;
                    padding-left: 4px;
                    color: #666666;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $main-color;
                    }
                }
            }
        }
        .inp-cbx:checked+.cbx span:first-child {
            background: $main-color;
            border-color: $main-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked+.cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked+.cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
        .btn-light {
            background-color: #f2f2f2;
            border: none;
            padding: 13px 25px 10px 25px;
            transition: 0.6s;
            font-weight: 600;
            display: block;
            width: 100%;
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
    .custom-payment-options {
        margin-top: 20px;
        span {
            display: block;
            color: #666666;
            margin-bottom: 8px;
        }
        .payment-methods {
            a {
                display: inline-block;
                img {
                    width: 40px;
                }
            }
        }
    }
}

.products-details-tab {
    margin-top: 50px;
    .tabs {
        list-style-type: none;
        margin-bottom: -1px;
        padding: {
            left: 0;
        }
        li {
            display: inline-block;
            line-height: initial;
            margin-right: 5px;
            a {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                color: $black-color;
                border: 1px dashed #eeeeee;
                text-decoration: none;
                padding: {
                    top: 14px;
                    bottom: 14px;
                    right: 30px;
                    left: 30px;
                }
                font: {
                    weight: 700;
                    size: 15px;
                }
                &:hover,
                &:focus {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;
        .tabs_item {
            display: none;
            &:first-child {
                display: block;
            }
            .products-details-tab-content {
                p {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .additional-information {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    text-align: left;
                    li {
                        border: 1px solid #eeeeee;
                        border-bottom: none;
                        padding: 10px 15px 7px;
                        color: #666666;
                        &:last-child {
                            border-bottom: 1px solid #eeeeee;
                        }
                        span {
                            display: inline-block;
                            width: 30%;
                            color: $black-color;
                            font: {
                                weight: 700;
                            }
                        }
                    }
                }
                h3 {
                    margin-bottom: 15px;
                    font: {
                        size: 24px;
                        weight: 700;
                    }
                }
                .product-review-form {
                    .review-title {
                        position: relative;
                        .rating {
                            display: inline-block;
                            .fas.fa-star {
                                color: #ffba0a;
                            }
                            i {
                                color: #ebebeb;
                                font-size: 14px;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline-block;
                            padding-left: 5px;
                            line-height: initial;
                        }
                        .default-btn {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            padding: 14px 25px;
                            background-color: $main-color;
                            color: $white-color;
                            transition: 0.6s;
                            &:hover {
                                background-color: $white-color;
                                color: $main-color;
                                text-decoration: none;
                            }
                        }
                    }
                    .review-comments {
                        margin-top: 35px;
                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;
                            .rating {
                                .fas.fa-star {
                                    color: #ffba0a;
                                }
                                i {
                                    font-size: 14px;
                                    color: #ebebeb;
                                }
                            }
                            h3 {
                                font-size: 18px;
                                margin: {
                                    top: 10px;
                                    bottom: 10px;
                                }
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 13px;
                                display: block;
                                strong {
                                    font-weight: 600;
                                }
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: #666666;
                                top: 40px;
                                text-decoration: underline;
                                font: {
                                    weight: 500;
                                    size: 15px;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    .review-form {
                        margin-top: 30px;
                        h3 {
                            margin-bottom: 20px;
                        }
                        form {
                            .form-group {
                                margin-bottom: 15px;
                                .form-control {
                                    height: 55px;
                                    box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
                                    border: none;
                                }
                                textarea.form-control {
                                    padding-top: 15px;
                                    height: auto;
                                }
                            }
                            .default-btn {
                                border: none;
                                margin-top: 10px;
                                box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
                                padding: 14px 25px;
                                background-color: $main-color;
                                color: $white-color;
                                border: 1px solid $main-color;
                                text-transform: uppercase;
                                &:hover {
                                    background-color: $white-color;
                                    color: $main-color;
                                    transition: 0.6s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.related-products {
    margin-top: 70px;
    text-align: center;
    .products-title {
        margin-bottom: 10px;
        text-align: center;
        span {
            font-size: 14px;
            color: $main-color;
        }
        h2 {
            font-size: 30px;
            color: $black-color;
            margin: 10px 0 0 0;
        }
    }
    .single-product-box {
        margin: {
            bottom: 0;
            top: 30px;
        }
    }
}


/*================================================
Cart Area CSS
=================================================*/

.cart-table {
    table {
        margin-bottom: 0;
        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 15px;
                    text-transform: uppercase;
                    border: none;
                    white-space: nowrap;
                    font: {
                        weight: 700;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: 0.6s;
                    white-space: nowrap;
                    padding: {
                        left: 0;
                        right: 0;
                    }
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    }
                    &.product-thumbnail {
                        a {
                            display: block;
                            img {
                                width: 80px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            color: #666666;
                            font-weight: 500;
                            display: inline-block;
                            text-decoration: none;
                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: $main-color;
                            float: right;
                            position: relative;
                            top: -1px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;
                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 48px;
                                transition: 0.6s;
                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $main-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                outline: 0;
                                box-shadow: none;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

.cart-buttons {
    margin-top: 30px;
    .shopping-coupon-code {
        position: relative;
        max-width: 530px;
        .form-control {
            height: 53px;
            outline: 0;
            box-shadow: none;
            border: 1px solid $main-color;
        }
        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 53px;
            background: $main-color;
            color: $white-color;
            border: none;
            padding: 0 25px;
            line-height: 54px;
            font-weight: 600;
            outline: 0;
            transition: 0.6s;
            outline: 0;
            box-shadow: none;
            &:hover {
                background-color: #3a60cd;
                color: $white-color;
            }
        }
    }
    .default-btn {
        padding: 14px 30px;
        background-color: $main-color;
        color: $white-color;
        border: 1px solid $main-color;
        text-decoration: none;
        display: inline-block;
        &:hover {
            background-color: $white-color;
            color: $main-color;
            transition: 0.6s;
        }
    }
}

.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 620px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        left: auto;
        right: auto;
        top: 50px;
    }
    h3 {
        margin-bottom: 25px;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;
        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            overflow: hidden;
            font: {
                weight: 600;
            }
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                border: {
                    top: none;
                }
            }
            b {
                font-weight: 500;
            }
            span {
                float: right;
                color: #666666;
                font: {
                    weight: normal;
                }
            }
        }
    }
    .default-btn {
        padding: 14px 30px;
        background-color: $main-color;
        color: $white-color;
        border: 1px solid $main-color;
        text-decoration: none;
        display: inline-block;
        &:hover {
            background-color: $white-color;
            color: $main-color;
            transition: 0.6s;
        }
    }
}


/*================================================
Checkout Area CSS
=================================================*/

.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 18px 20px 15px;
    margin-bottom: 65px;
    border-top: 3px solid $black-color;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: -35px;
        width: 100%;
        height: 1px;
        background: #eeeeee;
    }
    i {
        color: $black-color;
        margin-right: 2px;
    }
    span {
        display: inline-block;
        font-weight: 400;
        color: $black-color;
        font-size: 16px;
        a {
            display: inline-block;
            color: $black-color;
            text-decoration: none;
            &:hover,
            &:focus {
                color: $main-color;
            }
        }
    }
}

.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;
        label {
            display: block;
            margin-bottom: 7px;
            font-size: 16px;
            font: {
                weight: 400;
            }
            .required {
                color: $black-color;
            }
        }
        .nice-select {
            float: unset;
            line-height: 53px;
            color: #666666;
            font: {
                weight: 500;
            }
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: 0.6s;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
        .form-control {
            height: 50px;
            outline: 0;
            box-shadow: none;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
    }
    .form-check {
        margin-bottom: 20px;
        .form-check-label {
            color: $black-color;
        }
        label {
            position: relative;
            left: -3px;
            top: 1px;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}

.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        text-transform: uppercase;
                        padding: {
                            left: 20px;
                            top: 15px;
                            right: 20px;
                            bottom: 10px;
                        }
                        font: {
                            weight: 600;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: #666666;
                        border-color: #eaedff;
                        font-size: 15.5px;
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        }
                        &.product-name {
                            a {
                                color: #666666;
                                display: inline-block;
                                text-decoration: none;
                                font: {
                                    weight: 500;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        &.order-subtotal,
                        &.order-shipping,
                        &.total-price {
                            span {
                                color: #666666;
                                font-weight: 500;
                            }
                        }
                        &.shipping-price,
                        &.order-subtotal-price,
                        &.product-subtotal {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;
        .payment-method {
            p {
                [type="radio"] {
                    &:checked,
                    &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked,
                    &:not(:checked) {
                        +label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 600;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 3px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: "";
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 6px;
                                left: 3px;
                                border-radius: 50%;
                                transition: 0.6s;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        +label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        +label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            padding: 14px 30px;
            text-align: center;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
            text-decoration: none;
            color: $black-color;
            background-color: $white-color;
            transition: 0.6s;
            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
            &.order-btn {
                display: block;
                margin-top: 25px;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
            }
        }
    }
}


/*================================================
Footer Section CSS
=================================================*/

.footer-section {
    background-image: url(../../assets/img/footer-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding-top: 100px;
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: -1;
        left: 0;
        background-color: $white-color;
        opacity: 0.9;
        right: 0;
    }
    &.marketing-footer {
        background-color: #391343;
        background-image: unset;
        position: relative;
        z-index: 1;
        overflow: hidden;
        &::before {
            display: none;
        }
        &::after {
            content: "";
            position: absolute;
            top: -80px;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/shape/shape8.png);
            background-position: right;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
        }
        .footer-area-content {
            p {
                color: #d8c7e2;
            }
        }
        .single-footer-widget {
            h3 {
                color: $white-color;
            }
            .footer-quick-links {
                &.address-link {
                    a {
                        color: #d8c7e2;
                        position: relative;
                        padding-left: 40px;
                        i {
                            position: absolute;
                            left: 0;
                            top: 0;
                            color: #d8c7e2;
                        }
                        &:hover {
                            color: $main-color;
                            letter-spacing: normal;
                        }
                    }
                }
                a {
                    color: #d8c7e2;
                    &:hover {
                        color: $main-color;
                        letter-spacing: 1px;
                    }
                }
            }
            .social-list {
                li {
                    &.list-heading {
                        color: #d8c7e2;
                    }
                    a {
                        border: 1px solid #d8c7e2;
                        color: #d8c7e2;
                        &:hover {
                            background-color: $main-color;
                            border: 1px solid $main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .copyright-area {
            p {
                color: #d8c7e2;
                a {
                    color: #d8c7e2;
                }
            }
            ul {
                li {
                    a {
                        color: #d8c7e2;
                    }
                }
            }
        }
    }
    &.startup-footer {
        background-image: unset;
        position: relative;
        &::before {
            display: none;
        }
        .footer-area-content {
            p {
                color: #827f8d;
            }
            .default-btn {
                margin-top: 30px;
                background-color: #002c8c;
                color: $white-color;
                border: 1px solid #002c8c;
                &:hover {
                    border: 1px solid #002c8c;
                    color: #002c8c;
                    background-color: transparent;
                }
            }
        }
        .single-footer-widget {
            .footer-quick-links {
                &.address-link {
                    a {
                        color: #827f8d;
                        position: relative;
                        padding-left: 40px;
                        i {
                            position: absolute;
                            left: 0;
                            top: 0;
                            color: #827f8d;
                            transition: all 0.5s;
                        }
                        &:hover {
                            color: $main-color;
                            letter-spacing: normal;
                            i {
                                color: $white-color;
                            }
                        }
                    }
                }
                a {
                    color: #827f8d;
                    &:hover {
                        color: $main-color;
                        letter-spacing: 1px;
                    }
                }
            }
            .social-list {
                li {
                    &.list-heading {
                        color: #827f8d;
                    }
                    a {
                        border: 1px solid #827f8d;
                        color: #827f8d;
                        &:hover {
                            background-color: $main-color;
                            border: 1px solid $main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .shape-1 {
            position: absolute;
            bottom: 90px;
            right: 40px;
            z-index: -1;
            opacity: 0.5;
        }
        .shape-2 {
            position: absolute;
            top: 45px;
            right: 30px;
            z-index: -1;
            opacity: 0.2;
        }
        .shape-3 {
            position: absolute;
            top: 100px;
            left: 20px;
            z-index: -1;
            opacity: 0.5;
        }
        .shape-4 {
            position: absolute;
            bottom: 70px;
            left: 50px;
            z-index: -1;
            opacity: 0.5;
        }
    }
    &.seo-footer {
        background-image: unset;
        .footer-area-content {
            p {
                color: #827f8d;
            }
        }
        .single-footer-widget {
            .footer-quick-links {
                &.address-link {
                    a {
                        color: #827f8d;
                        i {
                            color: #827f8d;
                            transition: all 0.5s;
                        }
                        &:hover {
                            color: $main-color;
                            letter-spacing: normal;
                            i {
                                color: $white-color;
                            }
                        }
                    }
                }
                a {
                    color: #827f8d !important;
                    &:hover {
                        color: $main-color;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
    &.freelancer-footer {
        background-image: unset;
        background-color: #141016;
        &::before {
            display: none;
        }
        .footer-area-content {
            p {
                color: #d8c7e2;
                margin-bottom: 28px;
            }
            .default-btn {
                background-color: $main-color;
                color: $white-color;
                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                    border-color: $white-color;
                }
            }
        }
        .single-footer-widget {
            h3 {
                color: $white-color;
            }
            .footer-quick-links {
                &.address-link {
                    a {
                        color: #d8c7e2;
                        i {
                            color: #d8c7e2;
                            transition: all 0.5s;
                        }
                        &:hover {
                            color: $main-color;
                            letter-spacing: normal;
                            i {
                                color: $white-color;
                            }
                        }
                    }
                }
                a {
                    color: #d8c7e2 !important;
                    &:hover {
                        color: $main-color;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        .copyright-area {
            p {
                color: $white-color;
                a {
                    color: $white-color;
                }
            }
            ul {
                li {
                    a {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

.modal-dialog {
    margin: auto;
    max-width: 830px;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    .startup-form {
        max-width: 550px;
        position: relative;
        margin: auto;
        .input-newsletter {
            display: block;
            width: 100%;
            background-color: #f1f1f1;
            border: none;
            height: 64px;
            padding-left: 25px;
            border-radius: 4px;
            padding-top: 0;
            outline: 0;
            color: $black-color;
            &::placeholder {
                color: $paragraph-color;
            }
        }
        button {
            position: absolute;
            right: 7px;
            top: 7px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            padding: 14px 30px;
            border-radius: 5px;
            transition: 0.5s;
            text-transform: capitalize;
            font-size: 15px;
            font-weight: 400;
            font-size: 15px;
            font-weight: 400;
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
            i {
                margin-left: 5px;
            }
        }
    }
    .modal-header .close {
        padding: 0;
        margin: 0;
    }
    .modal-body {
        padding: 50px 30px;
    }
}

.footer-area-content {
    p {
        color: $paragraph-color;
        font-weight: 400;
        margin: 25px 0 0 0;
    }
}

.single-footer-widget {
    margin-bottom: 30px;
    h3 {
        margin-bottom: 30px;
        color: #3e435e;
        position: relative;
        font-size: 20px;
    }
    .footer-quick-links {
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 15px;
            &:last-child {
                padding-bottom: 0;
            }
        }
        a {
            color: $paragraph-color;
            transition: $transition;
            &:hover {
                color: $main-color;
                letter-spacing: 1px;
            }
        }
        &.address-link {
            li {
                padding-bottom: 0;
            }
            a {
                color: $paragraph-color;
                position: relative;
                padding-left: 40px;
                display: block;
                margin-bottom: 20px;
                i {
                    position: absolute;
                    left: 0;
                    top: -3px;
                    color: $paragraph-color;
                    width: 30px;
                    height: 30px;
                    border: 1px solid $paragraph-color;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 50%;
                    transition: all 0.5s;
                }
                &:hover {
                    color: $main-color;
                    letter-spacing: normal;
                    i {
                        color: $white-color;
                        background-color: $main-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
    .social-list {
        position: relative;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 25px;
        li {
            display: inline-block;
            margin-right: 5px;
            &.list-heading {
                color: $paragraph-color;
                margin-bottom: 0;
                padding-right: 10px;
                font: {
                    size: 18px;
                    weight: 400;
                }
            }
            a {
                width: 35px;
                height: 35px;
                line-height: 32px;
                background: transparent;
                border: 1px solid $paragraph-color;
                color: $paragraph-color;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                transition: 0.6s;
                -webkit-transition: 0.5s;
                &:hover {
                    background-color: $main-color;
                    border: 1px solid $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

.copyright-area {
    margin-top: 40px;
    border-top: 1px solid #eeeeee;
    font-size: 14px;
    padding: {
        top: 25px;
        bottom: 25px;
    }
    p {
        color: $paragraph-color;
        font-weight: 500;
        a {
            color: $paragraph-color;
            display: inline-block;
            font-weight: 500;
            &:hover {
                color: $main-color;
            }
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: right;
        li {
            display: inline-block;
            color: $paragraph-color;
            font-size: 15px;
            position: relative;
            font-weight: 500;
            margin: {
                left: 12px;
                right: 12px;
            }
            a {
                display: inline-block;
                color: $paragraph-color;
                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: "";
                position: absolute;
                top: 5px;
                right: -13px;
                width: 1px;
                height: 14px;
                background-color: #a0a0a0;
            }
            &:last-child {
                margin-right: 0;
                &::before {
                    display: none;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}


/*================================================
Go Top CSS
=================================================*/

.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: $white-color;
    background-color: $main-color;
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    visibility: hidden;
    transition: 0.9s;
    border-radius: 50%;
    &.active {
        top: 98%;
        transform: translateY(-98%);
        opacity: 1;
        visibility: visible;
    }
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: $transition;
        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $black-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        border-radius: 50%;
    }
    &:hover,
    &:focus {
        color: $white-color;
        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}


/*
Sidebar Menu Style
================================*/

.sidebar-modal {
    position: relative;
    .navbar-nav {
        li {
            a {
                padding: 10px 0px 10px 15px;
            }
        }
    }
    .modal.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 400px;
            height: 100%;
            transform: translate3d(0%, 0, 0);
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0;
            border: none;
            background-color: $white-color;
        }
        .modal-body {
            padding: 30px;
            .sidebar-modal-widget {
                margin-bottom: 35px;
                .title {
                    font-size: 18px;
                    font-weight: 700;
                    position: relative;
                    z-index: 1;
                    margin-bottom: 35px;
                    color: $black-color;
                    &::before {
                        position: absolute;
                        left: 0;
                        bottom: -7px;
                        width: 70px;
                        height: 2px;
                        border-radius: 5px;
                        background: $black-color;
                        content: "";
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    li {
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            font-size: 14px;
                            color: $white-color;
                            font-weight: 600;
                            text-transform: capitalize;
                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
                ul.contact-info {
                    li {
                        position: relative;
                        font-weight: 700;
                        padding-left: 45px;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        font-size: 14px;
                        color: $black-color;
                        span {
                            font-size: 13px;
                            display: block;
                            font-weight: 400;
                            color: #818992;
                            margin-top: 5px;
                            text-transform: initial;
                        }
                        i {
                            position: absolute;
                            left: 0;
                            top: 0;
                            border: 1px dashed $main-color;
                            border-radius: 50%;
                            color: $main-color;
                            text-align: center;
                            width: 35px;
                            height: 35px;
                            line-height: 24px;
                            font-size: 29px;
                            transition: all 0.5s;
                            &::before {
                                margin-left: 0;
                                font-size: 15px;
                            }
                        }
                        &:hover {
                            i {
                                color: $white-color;
                                border-color: $main-color;
                                background: $main-color;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .social-list {
                    text-align: left;
                    li {
                        display: inline-block;
                        padding-right: 5px;
                        a {
                            i {
                                width: 25px;
                                height: 25px;
                                border: 1px dashed $main-color;
                                display: inline-block;
                                text-align: center;
                                line-height: 25px;
                                border-radius: 50%;
                                color: $main-color;
                                font-size: 12px;
                                transition: all 0.5s;
                                &:hover {
                                    color: $white-color;
                                    background-color: $main-color;
                                    border-color: $main-color;
                                }
                            }
                        }
                        &:hover {
                            color: $white-color;
                        }
                    }
                    li {
                        a {
                            &:hover {
                                color: $white-color;
                            }
                        }
                    }
                }
                img {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .modal.right.fade.show {
        .modal-dialog {
            right: 0;
            transition: all 0.3s ease-in-out;
        }
    }
    .modal.right.fade {
        .modal-dialog {
            right: -320px;
            transition: all 0.3s ease-in-out;
        }
    }
    .modal-header {
        display: inline;
        padding: 0;
        border: none;
        .close {
            height: 30px;
            width: 30px;
            color: $black-color;
            margin: 0px;
            padding: 0px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            border: none;
            opacity: 1;
            i {
                &::before {
                    margin-left: 0;
                    font-size: 20px;
                }
            }
        }
        h2 {
            background-color: #f3f3f3;
            padding: 30px 25px;
        }
    }
    button {
        &:focus {
            outline: 0px;
        }
    }
}

.seo-blog-section {
    .blog-title-two {
        text-align: center;
        margin-bottom: 50px;
        span {
            font-size: 15px;
            color: #3e435e;
            font-weight: 400;
            margin-top: -5px;
            display: block;
        }
        h3 {
            font-size: 36px;
            margin: 5px 0 10px 0;
            color: #3f3360;
        }
    }
    .single-blog {
        margin-bottom: 30px;
        transition: all 0.5s;
        .blog-image {
            position: relative;
            background-color: #ffe6db;
            text-align: center;
            padding: 20px;
            .blog-bate {
                position: absolute;
                bottom: -10px;
                left: 20px;
                background-color: $main-color;
                padding: 10px 20px;
                text-align: center;
                span {
                    display: block;
                    font-size: 20px;
                    color: #fff;
                    line-height: 1;
                }
            }
        }
        &:hover {
            transform: translateY(-5px);
        }
    }
    .single-blog-item {
        .blog-content {
            padding: 20px;
            box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
            background-color: $white-color;
            .admin {
                font-size: 12px;
                margin-bottom: 10px;
                display: block;
                i {
                    margin-right: 5px;
                }
            }
            .blog-link {
                h3 {
                    font-size: 20px;
                    color: #3e435e;
                    margin-bottom: 18px;
                    transition: all 0.5s;
                }
                &:hover {
                    h3 {
                        color: $main-color;
                    }
                }
            }
            .read-more {
                i {
                    font-size: 12px;
                }
            }
        }
    }
}

.inner-team {
    background-color: $white-color;
    &::before {
        display: none;
    }
    &::after {
        display: none;
    }
}

.inner-update-section {
    &::before {
        display: none;
    }
    &::after {
        display: none;
    }
}