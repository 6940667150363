	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/12/2019 12:08
  	*/

@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/style-one/Flaticon.eot");
	src: url("../../assets/fonts/style-one/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/style-one/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/style-one/Flaticon.woff") format("woff"),
	url("../../assets/fonts/style-one/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/style-one/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/style-one/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-headphones:before { content: "\f100"; }
.flaticon-tag:before { content: "\f101"; }
.flaticon-cyber-security:before { content: "\f102"; }
.flaticon-innovation:before { content: "\f103"; }
.flaticon-content:before { content: "\f104"; }
.flaticon-unlink:before { content: "\f105"; }
.flaticon-data:before { content: "\f106"; }
.flaticon-goal:before { content: "\f107"; }
.flaticon-modules:before { content: "\f108"; }
.flaticon-work-time:before { content: "\f109"; }
.flaticon-it:before { content: "\f10a"; }
.flaticon-monitor:before { content: "\f10b"; }
.flaticon-appointment:before { content: "\f10c"; }
.flaticon-user:before { content: "\f10d"; }
.flaticon-dancer-motion:before { content: "\f10e"; }
.flaticon-cropping-tool:before { content: "\f10f"; }
.flaticon-brain-and-head:before { content: "\f110"; }
.flaticon-telephone:before { content: "\f111"; }
.flaticon-envelope:before { content: "\f112"; }
.flaticon-edit:before { content: "\f113"; }
.flaticon-placeholder:before { content: "\f114"; }